import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const LeslieDBourquin = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div className="container">
        <center>
          <div>
            <h2 className="heading-p1">Dr. Leslie D Bourquin</h2>
          </div>
          <div>
            <h6 className="heading-p2">
              Food Safety and Sustainability Specialist
            </h6>
          </div>
          <div>
            <img
              className="image"
              src="../images/DrLeslieDBourquin.png"
              alt=""
            />
          </div>
        </center>
      </div>
      <div className="row">
        <div class="col containerin">
          <span>
            Chairperson, Dept. of Food Science and Human Nutrition, Michigan
            State University, USA
          </span>
          <br />
          <b>Present</b>
          <ul className="ulstyle">
            <li>
              Chairperson, Food Science and Human Nutrition ,Michigan State
              University ,USA.
            </li>
          </ul>
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              Conducted trainings on food safety management systems including
              Hazard Analysis and Critical Control Points (HACCP), good
              manufacturing practices, hygiene and sanitation, good agricultural
              practices (pre-harvest food safety), and food safety programs for
              restaurants and retail food operations throughout the United
              States and internationally.
            </li>
            <li>
              He collaborates extensively with the private sector, academia,
              governments and International Organizations (e.g. WTO, APEC, World
              Bank) in implementing food safety capacity development projects
              globally.
            </li>
          </ul>
          <b>Professional Credentials</b>
          <ul className="ulstyle">
            <li>
              1997-Present International HACCP Alliance, Certified Lead
              Instructor
            </li>
            <li>
              2013-Present Institute of Food Technologists, Certified Food
              Scientist
            </li>
            <li>
              2013-Present National Registry of Food Safety Professionals, Food
              Safety HACCP
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              D., Nutritional Sciences, University of Illinois at
              Urbana-Champaign
            </li>
            <li>
              S., Animal Sciences, University of Illinois at Urbana-Champaign
            </li>
            <li>
              S., Agriculture (Animal Science), Oklahoma State University,
              Stillwater
            </li>
          </ul>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};
export default LeslieDBourquin;
