import React from "react";
import { Route } from "react-router-dom";

const PrivateRouter = (props) => {
	// console.log(props)
	// const firstLogin = localStorage.getItem("firstLogin");

	return (
		<Route {...props} />
		// firstLogin &&
	);
};

export default PrivateRouter;
