import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const RamachandraRaoTummala = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">CA Ramachandra Rao Tummala</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/Ca-ramchandra.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
              <b>Present</b>
              <br />
              <span>
                T R R & Associates, a chartered Accountants firm based out of
                Hyderabad, engaged to provide services in the areas of Audit,
                Investigation, Due Diligence, Accounting outsourcing, Taxation
                (Direct and Indirect), Company Law Matters for both domestic and
                foreign companies, Management Consultancy, Fixed Assets
                Verification, Information System Audit and FEMA.
              </span>
              <br />
            </div>

            <b>Past</b>
            <ul className="ulstyle">
              <li>
                SAP Technical Architect/Consultant – FICO Module (NTT Data ,
                Intelligroup Asia Pvt Ltd)
              </li>
              <li>Finance Controller – GE (Lighting Division)</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Technology Background</b>
          <ul className="ulstyle">
            <li>
              Over 15 years of Industry experience in Core Controllership
              Finance (GE and NTTData)
            </li>
            <li>SAP Consulting Experience ( Intelligroup Asia Pvt Ltd)</li>
            <li>E2E SAP Implementation.</li>
            <li>
              Hands on experience in accounting & controlling Audit, Internal
              and System Controls,Taxation,all commercial aspects of business.
            </li>
            <li>B1 USA VISA Holder-valid till 2023.</li>
          </ul>
          <b>International Exposure</b>
          <ul className="ulstyle">
            <li>
              Handled E2E SAP Implementation for NTT Data Inc- Assignment
              handled on site at Boston & Philadelphia in 2013
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>Chartered Accountant – FCA</li>
            <li>Bachelor of Commerce from Andhra University.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RamachandraRaoTummala;
