import React from "react";
import "../Menu.css";

const SurbhiAgarwal = () => {
  return (
    <>
      <div className="container">
        <center>
          <div>
            <h2 className="heading-p1">Surbhi Agarwal</h2>
          </div>
          <div>
            <h6 className="heading-p2">Co-Founder</h6>
          </div>
          <div>
            <img className="image" src="../images/Surbhi_Agarwal.jpg" alt="" />
          </div>
        </center>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Present</b>
          <ul className="ulstyle">
            <li>
              Director, Arete Consultants Pvt Ltd, a customised software
              development company with a focus on working with both national and
              international clients, I bring a unique perspective and skillset
              to the table. With over 8 years of experience in the software
              industry, I have honed my expertise in delivering software
              solutions that meet the needs of diverse clients across different
              markets and industries.
            </li>
            <li>
              At Arete Software, a division of Arete Consultants, she played a
              pivotal role in building and leading a team of experts in software
              development, project management, and customer service. Arete
              successfully delivered high-quality software solutions to clients
              both locally and abroad, helping them achieve their business
              objectives and stay ahead of their competition.
            </li>
            <li>
              Worked with the government, interacted at the Secretary level with
              various Central and State Ministries. Played a pivotal part in
              development of various projects within the Ministry of Agriculture
              and have represented them at multiple forums including FICCI and
              Assocham
            </li>
            <li>
              Also worked with startups, corporates and their mergers, working
              on the client strategies, projections, valuations, as well as
              presentations for investor meetings along with MIS presentations
              for the Board.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Master of Business Administration (MBA), Finance, International
              Business and Strategy from FORE School of Management, New Delhi in
              2015.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default SurbhiAgarwal;
