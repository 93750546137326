import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const ChetnaIpar = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Ms. Chetna Ipar</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/Chetna_Ipar.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Food Safety, Quality and Regulatory Consultant and Trainer
                (On-site and Off-site).
              </li>
              <li>PCQI Lead Instructor (USFDA FSPCA Recognized).</li>
              <li>
                BRCGS Global Standard Food Safety Issue 9 Lead Auditor Training,
                Auditor Training and Sites Training, Conversion for Auditors,
                Conversion for Sites.
              </li>
              <li>FSSC Lead Auditor.</li>
              <li>
                BRCGS Product Safety Management Courses such as Hazard Analysis
                and Critical Control Points (HACCP), Hazard Analysis and
                Risk-Based Preventive Controls (HARPC), Hazard Analysis and Risk
                Assessment (HARA), Internal Auditor, Root Cause Analysis, Risk
                Assessment, Validation and Verification, Vulnerability
                Assessment for Food Fraud, Environmental Monitoring.
              </li>
              <li>
                BRCGS Global Standard Food Safety Issue 9 Conversion for
                Auditors, Conversion for Sites, Auditor Training, Lead Auditor
                Training and Sites Training.
              </li>
              <li>USFDA Preventive Control of Human Food (PCQI).</li>
              <li>FSSAI based Quality and Regulatory requirements.</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div class="col containerin">
          <b>
            Designing Factory Compliances with regards to Audit Requirements
          </b>
          <br />
          <br />
          <ul className="ulstyle">
            <li>
              Food Safety Management System (HACCP, VACCP, TACCP and relevant
              systems).
            </li>
            <li>Hazard Analysis and Risk Assessment Plan.</li>
            <li>Quality Compliance and Documentation Guidance.</li>
            <li>Quality Assurance, GMP, GHP.</li>
            <br />
          </ul>
          <span>
            Conducting 2nd Party and 3rd Party Audits across the Food and
            Beverage Industry as per GFSI requirements, UNICEF, WHO and World
            Food Program (WFP). Regulatory Support as per USFDA, EFSA, Codex
            Alimentarius, FSSAI.
          </span>
          <br />
          <br />
          <li style={{ marginLeft: "30px" }}>Label Declarations.</li>
          <li style={{ marginLeft: "30px" }}>
            Product Guidelines and Categorization.
          </li>
          <li style={{ marginLeft: "30px" }}>
            Import and Export Guidelines and Compliances.
          </li>
          <br />
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              Worked with Nestle India and France; Ernst and Young; Hexagon
              Nutrition Private Limited in the departments of Product, Quality
              and Regulatory Compliance.
            </li>
            <li>
              Handled products in the categories of Chocolates and
              Confectionery, Dairy, Coffee and Beverages, Extruded Products and
              Snacks, Sauces and Seasonings, Functional Ingredients
              Nutraceuticals (Food for Special Dietary Uses; Food for Special
              Medical Purpose; Health Supplements; Ready to use Therapeutic
              Foods and Ready to Use Supplementary Foods; Fortified Rice
              Kernels, Vitamin and Mineral Premixes).
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Masters in Food Technology (Innovation and Management) –
              Wageningen University (The Netherlands).
            </li>
            <li>
              Bachelor of Technology (Food Engineering and Technology) –
              Institute of Chemical Technology, Mumbai (Formerly, U.D.C.T).
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ChetnaIpar;
