import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import InviteYourContacts from "./inviteyourcontacts";

export default function ProfileBanner() {
	const { profile, auth } = useSelector((state) => state);
	const [value, setValue] = useState("");

	return (
		<>
			<InviteYourContacts show={value} func={() => setValue(false)} />
			<div
				style={{
					backgroundImage: "url(./images/common-background.png)",
					height: "112px",
					backgroundRepeat: "no-repeat",
					backgroundSize: "cover",
				}}></div>
			<div className="row justify-content-around">
				{/* <div className="col-1"></div> */}
				<div
					className="card col-9"
					style={{
						marginTop: "-90px",
						padding: "13px",
						borderRadius: "15px",
					}}>
					<div className="row">
						{/* <div
									className="col-1"
									style={{ display: "flex", alignItems: "center" }}>
									<img
										src="./images/krishigaplogo.png"
										width="100px"
										height="100px"
										style={{
											border: "1px solid #ddd",
											borderRadius: "50%",
										}}></img>
								</div> */}
						<div className="col-7" style={{ paddingLeft: "30px" }}>
							<div style={{ fontSize: "22px", fontWeight: "600" }}>
								{auth.user.fullname}
							</div>
							<div style={{ fontSize: "17px", fontWeight: "600" }}>
								{auth.user.email}
							</div>

							<div>
								<img alt="location" src="./images/location_on.svg"></img>
								&nbsp;Hyderabad
							</div>
						</div>
						<div className="col-5">
							<div
								className="row"
								style={{ display: "flex", justifyContent: "flex-end" }}>
								<div
									className="col-3"
									style={{ width: "auto", paddingRight: "0px" }}></div>
								<div
									className="col-3"
									style={{ width: "auto", paddingRight: "0px" }}>
									{" "}
									<Button
										className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
										onClick={() => {
											setValue(true);
										}}
										style={{
											background: "#20ce8f",
											border: "none",
											borderRadius: "3px",
											marginRight: "10px",
										}}>
										+ Invite
									</Button>
								</div>
							</div>
							<br />
							<div
								className="row"
								style={{ display: "flex", justifyContent: "flex-end" }}>
								<div
									className="col-9"
									style={{
										display: "flex",
										justifyContent: "flex-end",
										fontSize: "14px",
									}}>
									Profile Link: &nbsp;
									{/* <a href="" style={{ color: "#41D298" }}>
										ulektz.com/c/krishigap
									</a> */}
									<a
										href=""
										style={{
											color: "#41D298",
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis",
										}}>
										{window.location.host}
										{window.location.pathname}
									</a>
								</div>
								<div className="col-3" style={{ width: "auto" }}>
									<button
										id="opensharetoggle"
										className="head-copy-btn"
										// onClick="openShareUrlModalHead()"
										style={{
											background: "#f4f7f9",
											border: "none",
											color: "#000",
											padding: "5px",
											borderRadius: "3px",
											fontSize: "12px",
											fontWeight: "400",
										}}
										onClick={() => {
											navigator.clipboard.writeText(window.location);
										}}>
										Copy &nbsp;
										<img src="./images/Copy_link.svg" alt="Share" />
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
