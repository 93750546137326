import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const JyotsanaSharma = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. (Mrs.) Jyotsana Sharma</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/jyotsana_sharma.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Principal Scientist – ICAR- National Research Centre on
                Pomegranate at Solapur, Maharashtra State, India. Joined this
                Centre in April 2006 and served in different capacities. Also
                Served as Acting Director from January 1, 2018 to April 27, 2021
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Joined ICAR as Scientist in June 1989 at Central Potato Research
                Station Jalandhar (ICAR-CPRI, Shimla). Did research on soil and
                tuber borne diseases of potato.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Awards</b>
          <ul className="ulstyle">
            <li>
              ‘Dalimb Ratna Award-2016’ Conferred by Akhil Maharashtra
              Pomegranate Growers Research Association for valuable
              contributions in development of model for management of bacterial
              blight disease (Telya) plant health management systems in
              pomegranate crops in all pomegranate growing states of India.
            </li>
            <li>
              Conferred Fellow of CHAI-2019. By Confederation of Horticulture
              Association of India, New Delhi for contribution and commitment to
              the furtherance of Horticulture.
            </li>
            <li>
              Conferred Fellow of Society for Promotion of Horticulture for the
              year 2021-22, by Society for Promotion of Horticulture, IIHR,
              Bengaluru, Karnataka India, in recognition of contributions in the
              field of Horticulture- Plant Pathology.
            </li>
            <li>
              Narinder Nath Mohan Memorial Gold Medal for outstanding
              performance in MSc.
            </li>
            <li>
              Dr. S Ramanujam Memorial Certificate of Appreciation 2003-04, by
              ICAR-CPRI, Shimla, for significant contributions in bringing
              potato revolution in India.
            </li>
          </ul>
          <b>Patents Obtained</b>

          <ul className="ulstyle">
            <li>
              One of the Inventors on the project for the patent granted to ICAR
              for an invention entitled Bio-formulation for Potassium fertilizer
              supplement in Pomegranate and process of preparation thereof.
              Patent No 403302
            </li>
            <li>
              A Process for Preparing a bio fertilizer cum bio fungicidal
              composition with Aspergillus niger strain AN27’ Notified in ‘The
              Gazette of India’, November 29, 2003
            </li>
          </ul>
          <b>Significant Achievements</b>
          <ul className="ulstyle">
            <li>
              Management of bacterial blight and other economically important
              diseases of pomegranate.
            </li>
            <li>
              Most recently developed, successfully demonstrated, ‘Stem
              solarisation: an eco-friendly, economical and effective technology
              to control bacterial blight’ in 4 farmers’ orchards
            </li>
            <li>
              Launched Social Media Platform ‘Dalimb Mitra’ collaborative
              project of ICAR- NRCP with Taral Infotech in March 2021, which was
              inaugurated by Hon’ble DG (Hort.) Dr AK Singh. Farmers can ask
              their queries in their local language and advisories are given
              every fifteen days
            </li>
          </ul>

          <b>Publications</b>
          <ul className="ulstyle">
            <li>
              Several research publications and technical /Extension bulletins,
              brochures and videos for the benefit of farmers in English,
              Marathi, and Hindi.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              PhD. in Plant Pathology from IARI, N. Delhi in 1989 (Grade point
              3.91/4).
            </li>
            <li>M.Sc. (with honours) in Plant Pathology(Grade point 3.93/4)</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JyotsanaSharma;
