import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const SumantDParkhi = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Mr. Sumant D. Parkhi</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/Sumant_D_Parkhi.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Consulting and training on Quality and Environment and Energy
                Management Systems.
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                More than 25years of consulting, training and auditing
                experience in various fields like management/environment system
                standards. ( QMS,EMS, EnMS, OHSAS,SA 8000).
              </li>
              <li>Completed total 1500+consultancy projects.</li>
              <li>
                Trained more than 1,25,000 participants from various
                organizations.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div class="col containerin">
          <b>Professional Qualifications</b>
          <ul className="ulstyle">
            <li>Principal Auditor–Quality Management System.</li>
            <li>Principal Auditor – Environmental Management Systems.</li>
            <li>Lead Assessor- HACCP.</li>
            <li>Lead Auditor –Energy Management System.</li>
            <li>SA8000 Introduction & Basic Auditor Course.</li>
          </ul>
          <b>Education</b>

          <ul className="ulstyle">
            <li>Diploma in Business Management (DBM).</li>
            <li>Master in Management Science (MMS).</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SumantDParkhi;
