import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const OnkarChoche = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Mr. Onkar Choche</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/Onkar_Choche.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>FSSC Lead Auditor.</li>
            </ul>
            <b>
              Food Technology Consultant, providing services in the domains of:
            </b>
            <ul className="ulstyle">
              <li>New Product Development</li>
              <li style={{ marginLeft: "40px" }}>
                Confectionery and Chocolates – Hard Boiled Candies; Center
                filled Candies; Jellies; Centre filled Jellies; Ice-lollies;
                Toffees; Wafer and Cream based Confectionery products; Cocoa
                based Chocolates and Confectionery products.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Beverages – Fruit Pulp / Fruit Concentrate based Beverages;
                Fruit or Flavour based Syrups and Concentrates, Carbonated
                Beverages, Fermented Beverages; Non-dairy Beverages.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Sauces – Emulsified sauces like Mayonnaise and its variants;
                Tomato Ketchup; Chutneys; Sriracha; Harissa; Pizza / Pasta
                Sauces like Arrabbiata, Marinara, Alfredo etc.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Extruded Products and Snacks – Cereal and Millet based extruded
                snacks; Traditional Extruded Products (like Kurdai, Sevai,
                Chakli, Murukku etc.); Noodles; Pasta.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Premixes – Fruit based beverage premixes; Traditional Cereal
                Pulses and Millet based premixes; Bakery premixes (like Cake
                premix; Cookie Premix, Whipped cream premix, Custard premix
                etc.).
              </li>
              <li style={{ marginLeft: "40px" }}>
                Bakery – Breads; Sponge Cakes; Creams; Biscuits; Cookies.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Dairy – Milk; Yogurt; Lassi; Dairy based sweets; Dairy based
                fermented products
              </li>
              <li style={{ marginLeft: "40px" }}>
                Seasoning – Seasonings for Snacks, Herb Seasonings; Masala
                seasonings.
              </li>
              <li>Completed total 1500+consultancy projects.</li>
              <li style={{ marginLeft: "40px" }}>
                Process Standardization from Prototyping to Commercial Scale-up
                stage.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Standardizing Product Specifications as per FSSAI and other
                applicable International Standards.
              </li>
              <li>Factory Support</li>
              <li style={{ marginLeft: "40px" }}>Factory Compliances.</li>
              <li style={{ marginLeft: "40px" }}>
                Food Safety Management System.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Hazard Analysis and Risk Assessment Plan.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Quality Compliance and Documentation Guidance.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Quality Assurance, GMP, GHP.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Training the factory team in product handling, process hygiene,
                documentation and record keeping, material handling etc.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Conducting Internal Audits and guide the factory team for 3rd
                Party audits.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Support in implementation of voluntary standards and
                certification like FSSC 22000, BRCGS, ISO etc.
              </li>
              <li>Regulatory Support as per applicable Standards</li>
              <li style={{ marginLeft: "40px" }}>Label Declarations.</li>
              <li style={{ marginLeft: "40px" }}>
                Understanding Claim Declarations.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Product Guidelines and Categorization.
              </li>
              <li style={{ marginLeft: "40px" }}>
                Import and Export Guidelines and Compliances.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              Worked with Future Consumer Limited, Keva Flavours Private
              Limited, Anshul Life Sciences in the departments of New Product
              Development and Product Applications.
            </li>
            <li>
              Worked with Future Consumer Limited, Keva Flavours Private
              Limited, Anshul Life Sciences in the departments of New Product
              Development and Product Applications.
            </li>
          </ul>
          <b>Education</b>

          <ul className="ulstyle">
            <li>
              Bachelor of Technology (Food Engineering and Technology) –
              Institute of Chemical Technology, Mumbai (Formerly, U.D.C.T).
            </li>
            <li>
              Post Graduate Program in Agribusiness Management (Part-time) –
              Prin. L. N. Wellingkar Institute of Management Development and
              Research, Mumbai.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OnkarChoche;
