import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const DiptiSaudagar = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Mrs Dipti Saudagar</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/Dipti_Saudagar.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Supporting in FSSAI compliance queries, Quality and FS systems
                guidance, HACCP building, any scale up challenges wrt to Baking
                technology, Product & Process Development technical aspects.
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                17 years of Food Industry Experience in Innovation Strategy
                Development, Product, Process & Technology Development, Quality
                & Food Safety Systems Development, and Operations Systems
                Development in companies like Cadburry, Firmenich and General
                Mills.
              </li>
              <li>
                Key Markets Handled India, Middle East & Africa, Malaysia,
                Singapore, Thailand, Philippines.
              </li>
              <li>
                Worked with below companies like Cadbury, Firmenich, General
                Mills.
              </li>
            </ul>
            <b>Education</b>
            <ul className="ulstyle">
              <li>M.Tech in Food Technology and Engineering.</li>
              <li>B.Tech.</li>
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default DiptiSaudagar;
