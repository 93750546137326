import React from "react";
// import './companyprofile.css';
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const CompanyProfile = () => {
	return (
		<div>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>
			{/* Section 1 */}
			<div className="section section-1">
				<div className="section-inner">
					<div className="text-container">
						<p>
							Global Academy for Food Safety & Sustainability Standards, an
							initiative from Krishigap Digital Solutions Pvt Ltd that was
							established in May 2023. Krishigap has already digitally empowered
							over 200 government agricultural extension officials and more than
							50 women Agripreneurs. It has also supported Farmers Producer
							Organizations in the successful implementation of India Good
							Agricultural Practices, launched by the Quality Council of India
							and aligned with the international Standard ISO 17065.
						</p>
					</div>
					<div className="image-container-1">
						<img
							loading="lazy"
							decoding="async"
							width="450"
							height="225"
							src="../images/companymicroscopeimg.jpg"
							alt="Company Image - Section 1"
						/>
					</div>
				</div>
			</div>

			{/* Section 2 */}
			<div className="section section-2">
				<div className="heading-1">
					<h2>BITS Pilani and Microsoft Startups Founders Hub</h2>
				</div>
				<div className="section-inner">
					<div className="content-container">
						<div className="text-container-2">
							<p className="mb-4">
								The Company is proud to announce that it has been selected as an
								evaluated startup under the prestigious{" "}
								<b>Conquest Initiative 2024,</b> a partnership between{" "}
								<b>BITS Pilani</b> and <b>Microsoft Startups Founders Hub.</b>{" "}
								This recognition opens transformative opportunities for our
								platform, the Global Academy on Food Safety and Sustainability
								Standards, by integrating cutting-edge AI solutions.
							</p>
							<ul>
								<li>Up to US$150,000 in Azure Credits.</li>
								<li>AI-driven innovation.</li>
								<li>Technical advisory and consulting.</li>
								<li>Free access to essential development tools.</li>
								<li>Go-to-market support with Microsoft.</li>
								<li>Strengthened tech teams and customer acquisition.</li>
							</ul>
						</div>
						<div className="image-container-2">
							<img
								decoding="async"
								src="../images/modelfarmimg2-1.png"
								alt="Microsoft Logo - Section 2"
							/>
							<img
								decoding="async"
								width="550"
								height="158"
								src="../images/modelfarmimg2-2.png"
								alt="BITS Pilani Logo - Section 2"
							/>
						</div>
					</div>
				</div>
			</div>

			{/* Section 3 */}
			<div className="section section-3">
				<div className="heading">
					<h2>MOU with Montgomery County</h2>
				</div>
				<div className="section-inner">
					<div className="text-container">
						<p className="mb-4">
							Krishigap has signed a MOU with Montgomery County, Maryland, USA,
							to leverage the Montgomery County International Soft Landing and
							Economic Development Program. This collaboration aims to
							facilitate Krishigap’s expansion in the U.S., providing strategic
							support and resources for successful market entry and growth.
						</p>
					</div>
					<div className="logo-container-1">
						<img
							decoding="async"
							src="..\images\modelfarmlogo1.png"
							alt="Montgomery County Logo - Section 3"
						/>
					</div>
				</div>
			</div>

			{/* Section 4 */}
			<div className="section section-4">
				<div className="heading">
					<h2>Collaboration with CABI</h2>
				</div>
				<div className="section-inner">
					<div className="text-container-2">
						<p className="mb-4">
							We are thrilled to partner with CABI, a not-for-profit
							organization, to offer their digital courses, including Crop Pest
							Diagnosis and Crop Pest Management Tools, for free on the
							Krishigap platform, enhancing agricultural practices.
						</p>
					</div>
					<div className="logo-container-2">
						<img
							decoding="async"
							width="350"
							height="158"
							src="..\images\modelfarmlogo2.png"
							alt="CABI Logo - Section 4"
						/>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default CompanyProfile;
