import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const Msrao = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. MS Rao</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/Dr-MS-Rao.png" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Former Head & Principal Scientist of ICAR – Indian Institute of
                Horticultural Research, Government of India.
              </li>
              <li>
                30 years of comprehensive experience in Project Management and
                Sustainable Agriculture, Campaigner for Sustainable Agriculture,
                Former Task Force Committee member of Department of Science and
                Technology & Department of Bio Technology, Govt of India.
              </li>
              <li>
                Former Consultant of FAO for promoting the use of bio pesticides
                in organic farming in Karnataka State
              </li>
              <li>
                INSA-Visiting scientist Royal Society of London, UK (1995)
              </li>
              <li>Fellow of National Academy of Agricultural Sciences</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Awards and Recognitions</b>
          <ul className="ulstyle">
            <li>
              DST-FICCI Award – Selected among the top 10 innovators in India.
              His technology has been selected for commercialization by IC2
              Institute of Texas University, Austin, USA under DST- Lockheed
              Martin Innovative Program and Department of Science and
              Technology, New Delhi.
            </li>
            <li>
              Received Dr. Vijyalakshmi Mojunder Memorial Award from ICAR-IARI
            </li>
          </ul>
          <b>Member in National Bodies</b>

          <ul className="ulstyle">
            <li>
              Former Member of Task Force Committee, Department of
              Biotechnology, Ministry of Science and Technology
            </li>
            <li>
              Former Member of Task Force Committee, Department of Science and
              Technology ,Ministry of Science and Technology
            </li>
          </ul>
          <b>Patents</b>
          <ul className="ulstyle">
            <li>
              He was granted seven International and one National Patent on Bio
              Pesticides
            </li>
          </ul>

          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Ph.D. in Agriculture Nematology – Indian Agriculture Research
              Institute, New Delhi
            </li>
            <li>
              Masters in Agriculture Nematology -Indian Agriculture Research
              Institute New Delhi
            </li>
            <li>
              Graduation in B. Sc (Ag) from Andhra Pradesh Agricultural
              University
            </li>
          </ul>
          <b>Contribution to Nation</b>
          <ul className="ulstyle">
            <li>
              He was instrumental in transferring Bio Pesticide technologies to
              620 industry licensees in India. These industries are producing
              more than 800 crores Bio Pesticides per year in India, which is
              used in millions of hectares
            </li>
          </ul>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};

export default Msrao;
