import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const PandurangGundappaAdsule = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. Pandurang Gundappa Adsule</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/pandurang-gundappaad-sule.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Presently working as international consultant for Post-Harvest
                Technology for ADB for Government of Bangladesh.
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Monitoring of pesticide residues in exportable table grapes to
                EU countries, funded by APEDA.
              </li>
              <li>
                Worked as Post-Doctoral Fellow-cum-Visiting Scientist at Citrus
                Research and Education Centre of IFAS under University of
                Florida in USA in 1982-83.
              </li>
              <li>
                Underwent UNIDO’s training programme in export from Small and
                Medium Enterprises at Phuket in Thailand in 1988 and Grain
                Milling Technology in Moscow in the former USSR (1989).
              </li>
              <li>
                Organized pilot trial of export of Indian banana shipment, a
                research trial to Dubai and studied marketing of these banana in
                Dubai market to decide technical feasibility and economic
                viability.
              </li>
              <li>
                Carried various programmes for the promotion and development of
                Small Scale Agro Rural Food Processing units as Regional Deputy
                Director (Food Industry), Southern states.
              </li>
              <li>
                Worked on evaluation of fruits and vegetable varieties for their
                suitability to processing purpose like canning, freezing,
                dehydration as Scientist at CISH, Lucknow.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>International Exposure</b>
          <ul className="ulstyle">
            <li>
              Participated in several international workshops on monitoring of
              agro chemical residues in fruits and vegetables- USSR, Italy,
              Germany, France, Austria, USA etc.
            </li>
          </ul>
          <b>Publications</b>

          <ul className="ulstyle">
            <li>
              120 research publications out of which 40 publications are in
              foreign journals like Journal of Food Science and Technology,
              Journal of Chromatography, AOAC, Journal of Food and Agriculture
              etc.
            </li>
            <li>
              Several research publications and technical /Extension bulletins,
              brochure and videos for the benefit of farmers in English,
              Marathi, and Hindi.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>BSc Agriculture.</li>
            <li>MSc Horticulture, PG School, IARI, New. Delhi.</li>
            <li>
              Ph.D., in First Class with Distinction in Horticulture at PG
              School, IARI, New. Delhi.
            </li>
            <li>
              Received G.J. Award for highest C.G.P.A. among successful
              candidates in IARI
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PandurangGundappaAdsule;
