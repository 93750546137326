import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const InviteYourContacts = (props) => {
	return (
		<>
			<Modal
				show={props.show}
				onHide={props.func}
				dialogClassName="modal-sm-30px"
				size="lg"
				// backdropClassName="childmodal"
				// backdrop="static"
				centered
				style={{ borderRadius: "55px" }}
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "20px 20px" }}>
					<div
						style={{ textAlign: "end", cursor: "pointer" }}
						onClick={props.func}>
						<img src="./images/closeOne.png"></img>
					</div>
					<div
						className="lightgreen"
						style={{ fontSize: "32px", textAlign: "center" }}>
						Invite your contact to uLektz
					</div>
					<div style={{ fontSize: "18px", textAlign: "center" }}>
						Syncing your contacts is the fastest way to grow your network
					</div>
					<div
						className="card"
						style={{
							padding: "30px 10px",
							margin: "20px 50px 20px 50px",
							background: "#fbfcfd",
						}}>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								fontSize: "18px",
								fontWeight: "400",
								marginBottom: "15px",
							}}>
							Directly invite your email contacts to join on uLektz
						</div>
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								marginBottom: "20px",
							}}>
							<textarea
								placeholder="Enter email address here,separated by comma"
								className="form-control"
								style={{ width: "400px", border: "1px solid green" }}
								rows="1"></textarea>
						</div>

						<div style={{ display: "flex", justifyContent: "center" }}>
							<Button
								className="cmn-btn uc-blue-btn  uc-community-join-btn combtn-unq"
								// onClick="joinForm('MTMy',this)"
								style={{
									background: "#20ce8f",
									border: "none",
									width: "fix-content",
									padding: "7px 15px",
									borderRadius: "4px",
									minWidth: "100px",
									marginBottom: "20px",
								}}>
								Invite
							</Button>
						</div>

						<div
							className="uc-modal-footer share-footer"
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: "20px 12px 12px 12px",
							}}>
							<div className="uc-inv-shr-icons" style={{ display: "flex" }}>
								<a
									href="https://wa.me/?text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
									className="popover__wrapper icons"
									id="whatsappComRef"
									target="_blank"
									rel="noreferrer">
									<img
										src="https://www.ulektz.com/assets/img/home/WhatsApp.svg"
										alt=""
									/>
									{/* <div className="popover__content">
                        <p>WhatsApp</p>
                      </div> */}
								</a>

								<a
									href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
									className="popover__wrapper icons"
									id="linkedinComRef"
									target="_blank"
									rel="noreferrer">
									<img
										src="https://www.ulektz.com/assets/img/home/linkedin.svg"
										alt=""
									/>
									{/* <div className="popover__content">
                        <p>LinkedIn</p>
                      </div> */}
								</a>

								<a
									href="https://www.facebook.com/dialog/share?app_id=604152004970299&amp;display=popup&amp;href=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
									className="popover__wrapper icons"
									id="facebookComRef"
									target="_blank"
									rel="noreferrer">
									<img
										src="https://www.ulektz.com/assets/img/home/fb.svg"
										alt=""
									/>
									{/* <div className="popover__content">
                        <p>Facebook</p>
                      </div> */}
								</a>

								<a
									href="https://twitter.com/intent/tweet?url=https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4&amp;text= Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up."
									className="popover__wrapper icons"
									id="twitterComRef"
									target="_blank"
									rel="noreferrer">
									<img
										src="https://www.ulektz.com/assets/img/home/twitter.svg"
										alt=""
									/>
									{/* <div className="popover__content">
                        <p>Twitter</p>
                      </div> */}
								</a>
								<input
									type="hidden"
									value="Join Global Food Safety and Sustainability Standards Platform for Networking, Learning and Development on uLektz to connect with members of our community, build new relationships, and get access to members-only resources for education, skills, and careers.Use this invite from me and earn up to ₹1000 for signing up.https://www.ulektz.com/c/krishigap/MzI4MDg1Mjk5NDc3MTMyODA4"
									id="copyComRefUrl"
								/>
								<a
									href="javascript: void(0);"
									className="popover__wrapper icons">
									<img
										src="https://www.ulektz.com/assets/img/home/Copy.svg"
										alt=""
									/>
									{/* <div className="popover__content">
                        <p>Copy message</p>
                      </div> */}
								</a>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default InviteYourContacts;
