import React from 'react';
// import '../Menu2.css'; // Make sure to create this CSS file
import Footer from "./footer";
import Navbar from "./navbar";
import Carousel from "react-multi-carousel";
import Homecarousel from './homecarousel';

const OurApproach = () => {

  return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>
			<div className="approach-container">
				<div className="approach-inner">
					<h2 className="approach-title">Approach to Address the Problem</h2>

					<div className="approach-content">
						<div className="approach-left">
							<div className="approach-item">
								<h4 className="approach-subtitle">Digital Learning Platform</h4>
								<p className="approach-description">
									Our EdTech platform provides virtual and instructor-led
									courses, delivering key insights on global food safety and
									sustainability standards across the entire food supply chain.
								</p>
							</div>

							<div className="approach-item">
								<h4 className="approach-subtitle">Model Farm Initiative</h4>
								<p className="approach-description">
									Our Model Farms in key crop production areas demonstrate the
									practical application of global standards. Aligned with ISO
									17065 and Smart Farming Initiatives, these farms serve as
									benchmarks and training centers for farmers, Farmer Producer
									Organizations (FPOs), agricultural extension officers,
									researchers, students, and food processors.
								</p>
							</div>
						</div>

						<div className="approach-right">
							<div className="approach-item">
								<h4 className="approach-subtitle">
									Onboarding Universities and Colleges
								</h4>
								<p className="approach-description">
									We partner with universities and colleges to offer AI-powered
									mobile apps for students and professionals. Key features
									include:
								</p>
								<strong>Institution Dashboard:</strong>
								<ul className="approach-list">
									<li>Access to online courses</li>
									<li>Upload custom courses</li>
									<li>Engage via discussion boards</li>
									<li>Access a digital library</li>
								</ul>
								<strong>KrishiGAP Dashboard:</strong>
								<ul className="approach-list">
									<li>Access to online courses</li>
								</ul>
								<p className="approach-description">
									Students and faculty can log in via their institution’s app or
									cloud platform to access both the institution’s and
									KrishiGAP’s resources.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default OurApproach;
