import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive1 = {
	desktop: {
		breakpoint: {
			max: 3000,
			min: 1400,
		},
		items: 1,
		partialVisibilityGutter: 40,
	},
	desktop1: {
		breakpoint: {
			max: 1400,
			min: 1024,
		},
		items: 1,
		partialVisibilityGutter: 40,
	},
	mobile: {
		breakpoint: {
			max: 767,
			min: 0,
		},
		items: 1,
		partialVisibilityGutter: 30,
	},
	tablet: {
		breakpoint: {
			max: 1024,
			min: 767,
		},
		items: 1,
		partialVisibilityGutter: 30,
	},
};

const Homecarousel = () => {
	return (
		<div className="" style={{}}>
			<Carousel
				responsive={responsive1}
				arrows={true}
				autoPlay
				infinite
				slidesToSlide={1}>
				<div style={{ height: "550px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding1.png')",
							// opacity: 0.7,
							// filter: "contrast(.7) brightness(.7)",
							height: "550px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}>
						<div className="row bgon-text">
							<div className="col-5" style={{}}>
								<div
									style={{
										opacity: "",
										backgroundColor: "rgb(139, 189, 123, 0.8)",
									}}>
									<div className="sectionimg-text">
										{" "}
										Guidance on Compliance with Global USFDA Standards in the
										Food Supply Chain
									</div>
								</div>
								{/* </div> */}
							</div>
						</div>
					</div>
				</div>
				<div style={{ height: "550px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding2.png')",
							// filter: "contrast(.7) brightness(.7)",
							height: "550px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}>
						<div className="row ">
							<div className="col-5 " style={{}}>
								<div
									className="bgon-text"
									style={{
										opacity: "",
										backgroundColor: "rgb(139, 189, 123, 0.8)",
									}}>
									<div className="sectionimg-text">
										{" "}
										AI&nbsp;Technology&nbsp;Poised&nbsp;to&nbsp;Revolutionize
										<br />
										Skill&nbsp;Development&nbsp;to&nbsp;facilitate&nbsp;compliance
										<br />
										&nbsp;with&nbsp;the&nbsp;Standards&nbsp;in&nbsp;the&nbsp;food&nbsp;supply&nbsp;chain
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div style={{ height: "550px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding3.jpg')",
							height: "550px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}>
						<div className="row ">
							<div className="col-5" style={{}}>
								<div
									className="bgon-text"
									style={{
										opacity: "",
										backgroundColor: "rgb(139, 189, 123, 0.8)",
									}}>
									<div className=" sectionimg-text">
										{" "}
										Unlock&nbsp;Your&nbsp;Potential&nbsp;and&nbsp;Evaluate&nbsp;Your
										<br />
										Expertise&nbsp;in&nbsp;Compliance&nbsp;with&nbsp;Food <br />
										Supply&nbsp;Chain&nbsp;Standards
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ height: "550px" }}>
					<div
						className="bg"
						style={{
							backgroundImage: "url('../images/homelanding4.jpg')",
							height: "550px",
							width: "100%",
							backgroundRepeat: "no-repeat",
							backgroundSize: "cover",
						}}>
						<div className="row ">
							<div className="col-5" style={{}}>
								<div
									className="bgon-text"
									style={{
										opacity: "",
										backgroundColor: "rgb(139, 189, 123, 0.8)",
									}}>
									<div className="sectionimg-text">
										{" "}
										At&nbsp;the&nbsp;Global&nbsp;Academy,&nbsp;we&nbsp;advocate
										for
										<br />
										inclusive,&nbsp;human-centered
										<br />
										learning,enhanced&nbsp;by&nbsp;the&nbsp;power&nbsp;of&nbsp;technology
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Carousel>
		</div>
	);
};

export default Homecarousel;
