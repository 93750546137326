import { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router } from "react-router-dom";

import PageRender from "./customRouter/PageRender";
import PrivateRouter from "./customRouter/PrivateRouter";
import { getNotifies } from "./redux/actions/notifyAction";
import { getPosts } from "./redux/actions/postAction";
import { getSuggestions } from "./redux/actions/suggestionsAction";

import SocketClient from "./SocketClient";
// import Home from "./components/home";
import { io } from "socket.io-client";

// import Foodsafetyacademy from "./pages/foodsafetyacademy";
import { refreshToken } from "./redux/actions/authAction";
import { GLOBALTYPES } from "./redux/actions/globalTypes";
import Home from "./pages/home";
function App() {
	const { auth, status, modal, userType } = useSelector((state) => state);
	const dispatch = useDispatch();
	// console.log("auth", auth, Object.keys(auth).length, userType);
	const firstLogin = localStorage.getItem("firstLogin");
	useLayoutEffect(() => {
		if (firstLogin) {
			dispatch(refreshToken());

			const socket = io();
			dispatch({ type: GLOBALTYPES.SOCKET, payload: socket });
			return () => socket.close();
		}
	}, [dispatch]);

	useEffect(() => {
		if (auth.token) {
			dispatch(getPosts(auth.token));
			dispatch(getSuggestions(auth.token));
			dispatch(getNotifies(auth.token));
		} else {
			dispatch(getPosts());
		}
	}, [dispatch, auth.token]);

	useEffect(() => {
		if (!("Notification" in window)) {
			alert("This browser does not support desktop notification");
		} else if (Notification.permission === "granted") {
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission().then(function (permission) {
				if (permission === "granted") {
				}
			});
		}
	}, []);

	function changeTheme(h) {
		document.documentElement.setAttribute("color-scheme", h);
	}
	// function ScrollToTop() {
	// 	const { pathname } = useLocation();
	// 	useEffect(() => {
	// 		window.scrollTo({
	// 			top: 0,
	// 			behavior: "smooth",
	// 		});
	// 	}, [pathname]);
	// 	return null;
	// }

	// window.onbeforeunload = function () {
	// 	window.scrollTo(0, 0);
	// };
	useEffect(() => {
		window.history.scrollRestoration = "manual";
	}, []);
	return (
		<Router>
			{/* <Navbar /> */}

			{/* <Alert /> */}
			{/* <ScrollToTop /> */}
			<input type="checkbox" id="theme" />
			<div className={`App ${(status || modal) && "mode"}`}>
				{window.location.href.indexOf("pdfreader") <= -1 && (
					<div className="main">
						{/* {status && <StatusModal />} */}
						{auth.token && <SocketClient />}
						{/* <Route path="/" component={Navbar}> */}
						<Route exact path="/" component={Home} />
						{/* <Route exact path="/home" component={Home} /> */}
						{/* <Route exact path="/login" component={Login} /> */}
						{/* <Route exact path="/register" component={Register} /> */}
						

						{/* <Route exact path="/community" component={Community} />
            <Route
              exact
              path="/communitykrishigap"
              component={CommunityKrishigap}
            /> */}
						{/* <Route exact path="/dashboard" component={Home} /> */}
						{/* </Route> */}
					</div>
				)}
				{/* {userType === "user" && ( */}
				<>
					{/* <Route exact path="/register" component={Register} /> */}

					<div className="wrap_page">
						<PrivateRouter exact path="/:page" component={PageRender} />
						<PrivateRouter exact path="/:page/:id" component={PageRender} />
					</div>
				</>
				{/* )} */}
			</div>
		</Router>
	);
}

export default App;
