import React from "react";
// import '../Menu2.css'; // New CSS file
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const PlatformFeatures = () => {
	return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>

			<div className="platform-features-container">
				<div className="features-inner-container">
					<div className="features-section-1">
						<div className="feature-item">
							<h4 className="feature-title">Food Safety Academy:</h4>
							<p className="feature-description">
								An AI-powered, cloud-based platform for global standards
								education.
							</p>
						</div>
						<div className="feature-item">
							<h4 className="feature-title">Professional Networking Space:</h4>
							<p className="feature-description">
								A discussion board for connecting professionals across the food
								industry.
							</p>
						</div>
					</div>
					<div className="features-section-2">
						<div className="feature-item">
							<h4 className="feature-title">
								Self-Paced & Instructor-Led Courses:
							</h4>
							<p className="feature-description">
								Designed for skill enhancement in food safety and
								sustainability.
							</p>
						</div>
						<div className="feature-item">
							<h4 className="feature-title">Internships & Job Placements:</h4>
							<p className="feature-description">
								Opportunities for hands-on experience and career advancement.
							</p>
						</div>
					</div>
					<div className="features-section-3">
						<div className="feature-item">
							<h4 className="feature-title">Digital Library:</h4>
							<p className="feature-description">
								A rich repository of eBooks, videos, and other learning
								resources.
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default PlatformFeatures;
