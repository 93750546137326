import React from "react";

export default function LoginRequest() {
	return (
		<>
			<div className="d-flex justify-content-center align-items-center flex-column mt-5">
				<div>Please login to proceed!!!</div>
				<br />
				<div>
					{/* <Link to="/login" style={{ color: "crimson" }}> */}
					<button
						onClick={() => (window.location.href = "/login")}
						className="btn btn-success">
						Login
					</button>
					{/* </Link> */}
				</div>
				<br />
			</div>
		</>
	);
}
