import "font-awesome/css/font-awesome.min.css";
import React from "react";
import "react-multi-carousel/lib/styles.css";
import "../Menu.css";
// import Carousel1 from "./carousel1";
import Carousel1 from "./carousel1";
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const Home = () => {
	return (
		<>
			<Navbar />

			<div style={{ backgroundColor: "#ffffff" }}>
				<div className="" style={{}}>
					<Homecarousel />
				</div>
				<div className="container">
					<div
						className="row pt-pb"
						// style={{ paddingTop: "100px", paddingBottom: "100px" }}
					>
						<div className="col">
							<div className="">
								<img
									style={{ width: "", height: "222px" }}
									src="../images/homelanding3.jpg"
									alt=""
								/>
							</div>
							<div className="row" style={{ marginTop: "-50px" }}>
								<div className="col">
									<div
										class=""
										style={{
											backgroundColor: "#15222c",
											paddingBottom: "20px",
											paddingTop: "20px",
											display: "flex",
											justifyContent: "space-evenly",
											alignItems: "center",
										}}>
										<div
											class="section-iconsize"
											style={{
												padding: "15px 25px",
												background: "#006436",
												borderRadius: "50px",
											}}>
											<i style={{ color: "#ffffff" }} class="fa fa-users"></i>
										</div>
										<div
											class=""
											style={{ display: "flex", flexDirection: "column" }}>
											<h2 style={{ marginBottom: "0px", color: "#ffffff" }}>
												4000+{" "}
											</h2>

											<p style={{ color: "#ffffff" }}>Satisfied Clients </p>
										</div>
									</div>
								</div>
								<div className="col">
									<img
										style={{
											borderRadius: "5px",
											height: "197px",
											border: "7px solid #ffffff",
										}}
										src="../images/natureimg.jpg"
										alt=""
									/>
								</div>
							</div>
						</div>
						<div className="col">
							<div class="">
								<h6 className="section1-tittle1">WHO WE ARE </h6>
								<h2 className="section1-tittle2">
									Ensuring Better Food Standards Worldwide
								</h2>{" "}
								<p className="section1-tittle3">
									We are committed to digitally empowering stakeholders across
									the food supply chain by promoting compliance with global Food
									Safety and Sustainability Standards. Through our innovative
									approach, we bridge the gap between knowledge and practice,
									supporting farmers, processors, and other food supply chain
									actors in meeting international standards.
								</p>
							</div>
							<div class="section1-button">
								<a
									href="/keyobjectives"
									target="_self"
									rel="nofollow"
									class="link-anime v2">
									Read More{" "}
								</a>
							</div>
						</div>
					</div>
				</div>

				<div className="container page-gap">
					<div className="row ">
						<div className="col-3">
							<div
								class=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									class="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i style={{ color: "#ffffff" }} class="fa fa-users"></i>
								</div>
								<div
									class=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Team Member
									</p>
								</div>
							</div>
						</div>
						<div className="col-3">
							<div
								class=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									class="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i
										style={{ color: "#ffffff" }}
										class="fa fa-graduation-cap"></i>
								</div>
								<div
									class=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Placement Opportunities
									</p>
								</div>
							</div>
						</div>
						<div className="col-3">
							<div
								class=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									class="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i style={{ color: "#ffffff" }} class="fa fa-folder-open"></i>
								</div>
								<div
									class=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Digital Library
									</p>
								</div>
							</div>
						</div>
						<div className="col-3">
							<div
								class=""
								style={{
									backgroundColor: "#BBE4A3",
									paddingBottom: "60px",
									paddingTop: "60px",
									display: "flex",
									justifyContent: "space-evenly",
									alignItems: "center",
									position: "relative",
								}}>
								<div
									class="section-iconsize"
									style={{
										display: "flex",
										position: "absolute",
										top: "-76px",
										padding: "50px 50px",
										background: "#2d947a",
										borderRadius: "10px",
									}}>
									<i style={{ color: "#ffffff" }} class="fa fa-handshake"></i>
								</div>
								<div
									class=""
									style={{ display: "flex", flexDirection: "column" }}>
									<span className="econamy-number">200+ </span>

									<p className="econamy-text" style={{}}>
										Client Review
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="pt-pb " style={{ backgroundColor: "#BBE4A3" }}>
					<div className="row ">
						<div className="col section2-tittle-flex">
							<h2 className="section1-tittle2">What Global Academy does </h2>
							<p
								className="section1-tittle3"
								style={{ textAlign: "center", margin: "0px" }}>
								Bridging the gap between knowledge and practice and empowering
								farmers to <br /> reduce their carbon footprint by adhering to
								these standards.
							</p>
						</div>
					</div>
					<div
						className="row  section3-margin  fadeInUp-animation homecard-flex"
						style={{}}>
						<div class="col-4 buttonshow pd-col">
							<div
								class=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div class="section-iconsize">
										<i class="fa fa-book"></i>
									</div>
									<div class="">
										<div className="section3-text1">Courses</div>
										<p className="section3-text2">
											Skill Development Courses on Global Food Safety and
											Sustainability Standards to strengthen your expertise.
										</p>
									</div>
								</div>
								<div class="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/coursesnew1.png"
											alt=""
										/>
									</div>
									<div
										class="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="./courses/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af"
											target=""
											rel=""
											class="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												class="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 buttonshow pd-col">
							<div
								class=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div class="section-iconsize">
										<i aria-hidden="true" class="fa fa-folder-open"></i>
									</div>
									<div class="">
										<div className="section3-text1">Digital Library</div>
										<p className="section3-text2">
											Collection of videos on good agricultural practices in the
											implementation of On Farm Production related Standards.
										</p>
									</div>
								</div>
								<div class="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/digitallibrarynew.jpg"
											alt=""
										/>
									</div>
									<div
										class="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="/digitallibrary/9ae8b71c-dd3f-4fad-b1ee-e872f951e7af"
											target=""
											rel=""
											class="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												class="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 buttonshow pd-col">
							<div
								class=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div class="section-iconsize">
										<i aria-hidden="true" class="fa fa-users"></i>
									</div>
									<div class="">
										<div className="section3-text1">Discussion Board</div>
										<p className="section3-text2">
											Join the &nbsp; discussion Board to engage with &nbsp;
											peers and professionals &nbsp; &nbsp; in the food safety
											and sustainability sector.
										</p>
									</div>
								</div>
								<div class="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/eventsmeet.jpg"
											alt=""
										/>
									</div>
									<div
										class="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href=""
											target=""
											rel=""
											class="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												class="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 buttonshow pd-col">
							<div
								class=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div class="section-iconsize">
										<i class="fa fa-graduation-cap"></i>
									</div>
									<div class="">
										<div className="section3-text1">
											Placement Opportunities
										</div>
										<p className="section3-text2">
											Opportunities for for food safety professionals and
											graduates across the food supply chain.
										</p>
									</div>
								</div>
								<div class="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/jobsnew.jpg"
											alt=""
										/>
									</div>
									<div
										class="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href=""
											target=""
											rel=""
											class="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												class="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-4 buttonshow pd-col">
							<div
								class=""
								style={{ background: "#fff", borderRadius: "10px" }}>
								<div className=" section3-p">
									<div class="section-iconsize">
										<i class="fa fa-gears"></i>
									</div>
									<div class="">
										<div className="section3-text1">Regulatory Compliances</div>
										<p className="section3-text2">
											Ensuring compliance at every stage of the food chain is
											essential for food safety, quality, and market access.
										</p>
									</div>
								</div>
								<div class="" style={{ position: "relative" }}>
									<div className="section3-image">
										<img
											className=""
											style={{ width: "100%" }}
											src="../images/regulatorycompliance.png"
											alt=""
										/>
									</div>
									<div
										class="section3-button section3-display"
										style={{
											position: "absolute",
											bottom: "0px",
											width: "100%",
										}}>
										<a
											style={{
												color: "#fff",
												justifyContent: "space-between",
												alignItems: "center",
											}}
											href="./regulatorycompliances"
											target=""
											rel=""
											class="">
											Read More{" "}
											<i
												style={{ alignSelf: "right" }}
												class="fa fa-chevron-right"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container pt-pb1">
					<div>
						<h6 className="section1-tittle1">CLIENTS REVIEW </h6>
						<h2 className="section1-tittle2">
							Expert Guidance For Your <br /> Journey
						</h2>{" "}
					</div>
				</div>
				<div className="" style={{ paddingBottom: "100px" }}>
					<Carousel1 />
				</div>
			</div>
			<Footer />
		</>
	);
};
export default Home;
