import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const SNSingh = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr S.N. Singh</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/S-N-Singh.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Ex-Principal Scientist (Agronomy), ICAR-Indian Institute of
                Sugarcane Research, Lucknow, India.
              </li>
              <li>
                Worked with CIMMYT sponsored USAID project on“Accelerating the
                tillage revolution in the Indus-Gangesbasin: Fostering Resource
                Conservation Technologies topromote economic growth, resource
                conservation, andfoodsecurity.”.
              </li>
              <li>
                Worked as Co-coordinator with respect to the sugarcane training
                program for the scientists from Bangladesh.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Recognitions</b>
          <ul className="ulstyle">
            <li>
              Conferred with Lifetime Contribution Award by Society for Sugar
              Research & Promotion , Lucknow – Year 2022.
            </li>
            <li>
              Conferred Best KVK Award, Pandit Deendaya lUpadhyay Krishi Vigyan
              Protsahan Puraskar by the Hon’ble Prime Minister of India New
              Delhi – Year 2018 on behalf of ICAR, New Delhi.
            </li>
            <li>
              Conferred Award of Excellence during International conference on
              “Green Technologies for Sustainable Development of Sugar and
              Related Industries – ICAR-IISR: Year 2019.
            </li>
            <li>
              Conferred Dr. O. P. GautamVishisht KrishiVaigyanikPuraskar2015 by
              the Hon’ble Governor ofUttarPradeshat CSAU&T, Kanpur -2016.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Post Graduation in M.Sc. Agronomy (71.52%) at C.S.A.U of Kanpur in
              1983.
            </li>
            <li>
              Doctorate in Ph.D. Agronomy at Agra University of Agra in 1993.
            </li>
          </ul>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};

export default SNSingh;
