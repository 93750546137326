import React from "react";
import "../Menu.css";

const AdvisoryBoard = () => {
  return (
    <div className="container" id="">
      <center>
        <h3
          className="pt-3 pb-3"
          style={{ fontFamily: "Rajdhani, Sans-serif", fontWeight: 700 }}
        >
          Advisory Board
        </h3>
      </center>
      <center>
        <strong>
          <p>
            The team is assisted by experienced professionals in the industry
            segment
          </p>
        </strong>
      </center>

      <div className="row">
        {/* Mr. Anil Jauhri */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="position-relative d-flex justify-content-center">
              <div>
                <center>
                  <a href="./aniljauhri">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="../images/anil-jauhri.png"
                      alt="Mr. Anil Jauhri"
                    />
                  </a>
                </center>
              </div>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./aniljauhri">
                <h5 className="advisory-person-name">Mr. Anil Jauhri</h5>
              </a>
              <small>International Standards Expert</small>
              <br />
              <strong>
                Ex CEO, National Accreditation Board for Certification Bodies,
                Quality Council of India
              </strong>
            </div>
          </div>
        </div>

        {/* Ms. Shashi Sareen */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="position-relative d-flex justify-content-center">
              <div>
                <center>
                  <a href="./shashisareen">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="../images/Ms-Shashi-Sareen.jpg"
                      alt="Ms. Shashi Sareen"
                    />
                  </a>
                </center>
              </div>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./shashisareen">
                <h5 className="advisory-person-name">Ms. Shashi Sareen</h5>
              </a>
              <small>Export Compliance and Food Safety Standards Expert</small>
              <br />
              <strong>
                Ex CEO, Export Inspection Council, Ex Director, Bureau of Indian
                Standards.
              </strong>
            </div>
          </div>
        </div>

        {/* Mr. Ramesh Penmetcha */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="overflow-hidden">
              <center>
                <a href="./rameshpenmetcha">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="../images/Ramesh.jpg"
                    alt="Mr. Ramesh Penmetcha"
                  />
                </a>
              </center>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./rameshpenmetcha">
                <h5 className="advisory-person-name">Mr. Ramesh Penmetcha</h5>
              </a>
              <small>Information Technology Specialist</small>
              <br />
              <strong>
                Former MD, New York Stock Exchange Held Senior Leadership roles
                for over three decades
              </strong>
            </div>
          </div>
        </div>

        {/* Mr. Baskar Kotte */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="position-relative d-flex justify-content-center">
              <div>
                <center>
                  <a href="./baskarkotte">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="../images/BaskarKotte.jpg"
                      alt="Mr. Baskar Kotte"
                    />
                  </a>
                </center>
              </div>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./baskarkotte">
                <h5 className="advisory-person-name">Mr. Baskar Kotte</h5>
              </a>
              <small>International Standards Specialist</small>
              <br />
              <strong>Founder Quality System Enhancement Inc, USA</strong>
            </div>
          </div>
        </div>
      </div>
      <div className="row main-row-container">
        {/* Dr. Leslie D. Bourquin */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="position-relative d-flex justify-content-center">
              <div>
                <center>
                  <a href="./lesliedbourquin">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="../images/DrLeslieDBourquin.png"
                      alt="Dr. Leslie D. Bourquin"
                    />
                  </a>
                </center>
              </div>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./lesliedbourquin">
                <h5 className="advisory-person-name">Dr. Leslie D Bourquin</h5>
              </a>
              <small>Food Safety and Sustainability Specialist</small>
              <br />
              <strong>
                Chairperson, Dept. of Food Science and Human Nutrition, Michigan
                State University, USA
              </strong>
            </div>
          </div>
        </div>

        {/* Mr. Sudhakar Gande */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="overflow-hidden" style={{ background: "#d8f5c6" }}>
              <center>
                <a href="./sudhakargande">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="../images/sudhakargandeimg.jpg"
                    alt="Mr. Sudhakar Gande"
                  />
                </a>
              </center>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./sudhakargande">
                <h5 className="advisory-person-name">Mr. Sudhakar Gande</h5>
              </a>
              <small>Investment Banker, Tech Entrepreneur</small>
              <br />
              <strong>Ex Sr. Director, American Express Bank</strong>
              <br />
              <strong>Former CEO, Jupiter Capital Pvt. Ltd.</strong>
              <br />
              <strong>Former Vice Chairman, AxisCades Technologies Ltd.</strong>
            </div>
          </div>
        </div>

        {/* Mr. Sanjay Dave */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div
              className="position-relative d-flex justify-content-center"
              style={{ background: "#bbe4a3" }}
            >
              <div>
                <center>
                  <a href="./sanjaydave">
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src="../images/MrSanjayDave.png"
                      alt="Mr. Sanjay Dave"
                    />
                  </a>
                </center>
              </div>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./sanjaydave">
                <h5 className="advisory-person-name">Mr. Sanjay Dave</h5>
              </a>
              <small>Food Safety Specialist</small>
              <br />
              <small>Ex Global Chair Person Codex</small>
            </div>
          </div>
        </div>

        {/* Mr. Gopi Krishna Santhanam */}
        <div className="col-lg-3 col-md-6 d-flex">
          <div className="team-item d-flex flex-column w-100">
            <div className="overflow-hidden">
              <center>
                <a href="./gopikrishnasanthanam">
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="../images/gopikrishna.jpg"
                    alt="Mr. Gopi Krishna Santhanam"
                  />
                </a>
              </center>
            </div>
            <div
              className="text-center1 p-4"
              style={{
                background: "#bbe4a3",
                borderBottomLeftRadius: "8px",
                borderBottomRightRadius: "8px",
              }}
            >
              <a href="./gopikrishnasanthanam">
                <h5 className="advisory-person-name">
                  Mr. Gopi Krishna Santhanam
                </h5>
              </a>
              <small>Information Technology and Smart Farming Specialist</small>
              <br />
              <small>Co-Founder and CEO, Ascentya, R&D Solutions Pvt Ltd</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisoryBoard;
