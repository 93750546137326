import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const Jagadeeshwar = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr R. Jagadeeshwar</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/Jagadeeshwar.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Director of Research, Professor Jay Shankar Telangana State
                Agricultural University.
              </li>
              <li>
                He is credited with identification and release of twelve (12)
                blast resistant rice varieties for Telangana State. Worked on
                rice research over 12 years.
              </li>
              <li>
                Developed eco-friendly disease management package of organic
                rice cultivation and innovative strategies like silver based
                nano-fungicides for management of rice sheath blight.
              </li>
              <li>
                Rice Research Centre, ARI, Rajendra nagar was adjudged Best
                AICRIP Plant Pathology Centre and Best Overall AICRIP Centre
                during 2012-13 and 2013-14,respectively owing to innovative
                research and coordination by the team effort under his able
                guidance.
              </li>
              <li>
                He worked on important diseases of rice, soybean, groundnut,
                sesame, turmeric and chilly with specialization in plant
                virology, characterizing chili viral diseases of Northern
                Telangana Zone.
              </li>
              <li>
                He also identified several novel fungicide molecules to combat
                various rice diseases and compatible pesticide combinations
                which also enabled farmers to reduce plant protection costs.
              </li>
              <li>
                Developed eco-friendly disease management package of organic
                rice cultivation and innovative strategies like silver based
                nano-fungicides for management of rice sheath blight.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Recognitions</b>
          <ul className="ulstyle">
            <li>Best Extension Scientistaward.</li>
            <li>
              Best Research Scientist award, Fellow of Indian Society of Plant
              Pathologists, Fellow of Plant Protection Association of India
            </li>
            <li>Padmasri I. V. Subba Rao Rythunestham award.</li>
            <li>Best seed scientist award.</li>
            <li>
              Rice Research Centre, ARI, Rajendranagar was adjudged Best AICRIP
              Plant Pathology Centre and Best Overall AICRIP Centre during
              2012-13 and 2013-14, respectively owing to innovative research and
              coordination by the team effort under his able guidance.
            </li>
            <li>
              Under his leadership as Director of Research several AICRIP
              schemes received national level awards and labs received National
              Accreditations.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>Ph. D. in Plant Pathology.</li>
            <li>M.Sc., Ag.</li>
          </ul>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};

export default Jagadeeshwar;
