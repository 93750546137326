import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export default function AddExistingFiles(props) {
	console.log("props add existing files", props);
	const [showExistingFilesModel, setShowExistingFilesModel] = useState(false);
	const [courses, setCourses] = useState([]);
	const [enablecheckeditems, setEnableCheckeditems] = useState([]);
	const [enablesectioncheckeditems, setEnableSectionCheckeditems] = useState(
		[]
	);
	const [sections, setSections] = useState([]);

	useEffect(() => {
		setCourses(props.courses);
	}, []);

	useEffect(() => {
		let tempSections = [];
		if (courses.length !== 0) {
			courses.forEach((course) => {
				course.textlist.forEach((value) => {
					if (!tempSections.includes(value.section) && value.file !== "") {
						tempSections.push(value.section);
					}
				});
			});
		}
		console.log("tempSections", tempSections);
		setSections(tempSections);
	}, [courses]);

	const handlePushItemIntoTextlistArray = () => {
		let finalCourses = [];
		courses.forEach((course) => {
			course.textlist.forEach((item) => {
				enablecheckeditems.forEach((value) => {
					if (item.name === value) {
						var duplicate = false;
						finalCourses.forEach((course) => {
							if (course.name === value) {
								duplicate = true;
							}
						});
						if (duplicate === false) {
							finalCourses.push(item);
						}
					}
				});
			});
		});
		console.log("finalcourses", finalCourses);
		props.addFiles(finalCourses);
		setShowExistingFilesModel(false);
	};

	const handleEnableSectionCheckbox = (event) => {
		const { checked, value } = event.currentTarget;
		console.log("value", value);
		setEnableSectionCheckeditems((prev) =>
			checked ? [...prev, value] : prev.filter((val) => val !== value)
		);

		courses.forEach((course) => {
			course.textlist.forEach((item) => {
				if (item.section === value) {
					setEnableCheckeditems((prev) =>
						checked
							? [...prev, item.name]
							: prev.filter((val) => val !== item.name)
					);
				}
			});
		});
	};

	const handleEnableCheckbox = (event) => {
		const { checked, value } = event.currentTarget;
		console.log("value", value);

		setEnableCheckeditems((prev) =>
			checked ? [...prev, value] : prev.filter((val) => val !== value)
		);

		if (!checked) {
			courses.forEach((course) => {
				course.textlist.forEach((item) => {
					if (item.name === value) {
						setEnableSectionCheckeditems((prev) =>
							prev.filter((val) => val !== item.section)
						);
					}
				});
			});
		}
	};

	useEffect(() => {
		console.log(
			"enableCheckeditems",
			enablecheckeditems,
			enablesectioncheckeditems
		);
	}, [enablecheckeditems, enablesectioncheckeditems]);

	return (
		<>
			<Modal
				show={showExistingFilesModel}
				onHide={() => setShowExistingFilesModel(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Existing Course Materials
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setShowExistingFilesModel(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						{sections.map((c) => {
							if (c !== "") {
								return (
									<>
										{/* <Accordion
											style={{
												margin: "10px 0",
											}}>
											<Accordion.Item eventKey={c}>
												<Accordion.Header
													style={{
														borderBottom: "1px solid #ddd",
													}}>
													<p
														style={{
															fontWeight: 900,
															color: "#36BB7D",
														}}>
														{c}
													</p>
												</Accordion.Header>

												<Accordion.Body
													style={{
														padding: "15px",
														borderTop: "1px solid #ddd",
														// display: "flex",
													}}> */}
										<p
											style={{
												fontWeight: 900,
												color: "#36BB7D",
												margin: "10px 0",
											}}>
											<input
												type="checkbox"
												value={c}
												checked={enablesectioncheckeditems.some(
													(val) => val === c
												)}
												onChange={handleEnableSectionCheckbox}
											/>
											<span style={{ marginLeft: "5px" }}>{c}</span>
										</p>
										{courses.map((course, i) => {
											return course.textlist.map((e, index) => {
												if (e.section === c) {
													return (
														<div
															className="d-flex"
															style={{ marginLeft: "10px" }}>
															<input
																type="checkbox"
																value={e.name}
																checked={enablecheckeditems.some(
																	(val) => val === e.name
																)}
																onChange={handleEnableCheckbox}
																// checked={disableallChecked}
																// onChange={handleAllDisableCheckbox}
															/>
															<div
																style={{
																	marginLeft: "5px",
																}}>
																{e.name}
															</div>
															<div
																style={{
																	cursor: "pointer",
																	marginLeft: "15px",
																	textDecoration: "underline",
																}}
																onClick={() => {
																	props.showPdf(e.file);
																	// fileConversion(e.file);
																}}>
																View
															</div>
														</div>
													);
												}
											});
										})}
										{/* </Accordion.Body>
											</Accordion.Item>
										</Accordion> */}
									</>
								);
							} else {
								return courses.map((course, i) => {
									return course.textlist.map((e, index) => {
										if (e.section === c && e.file !== "") {
											return (
												<div className="d-flex align-items-end">
													<p
														style={{
															fontWeight: 900,
															color: "#36BB7D",
															margin: "10px 0",
														}}>
														<input
															type="checkbox"
															value={e.name}
															checked={enablecheckeditems.some(
																(val) => val === e.name
															)}
															onChange={handleEnableCheckbox}
														/>
														<span style={{ marginLeft: "5px" }}>{e.name}</span>
													</p>
													<div
														style={{
															cursor: "pointer",
															marginLeft: "15px",
															textDecoration: "underline",
														}}
														onClick={() => {
															props.showPdf(e.file);
															// fileConversion(e.file);
														}}>
														View
													</div>
												</div>
											);
										}
									});
								});
							}
						})}
						{/* <div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Course Name:</div>
							{/* <input
								type="text"
								className="form-control"
								placeholder="Enter Course Categorie name"
								onChange={(e) => setCourseName(e.target.value)}></input> *
						</div>
						<div
							style={{
								paddingTop: "15px",
								paddingLeft: "10px",
								paddingBottom: "10px",
							}}>
							<div style={{ fontSize: "13px" }}>
								Course Categorie Description:
							</div>
						</div> */}
					</div>
					<div style={{ textAlign: "center", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							// disabled={courseName === "" || courseDescription === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoTextlistArray();
							}}>
							Add
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<span
				onClick={() => setShowExistingFilesModel(true)}
				style={{ cursor: "pointer" }}>
				<img
					alt="icon"
					style={{
						width: "18px",
						height: "18px",
						background: "#20ce8f",
						borderRadius: "20px",
					}}
					src="../images/Icon.png"></img>
				&nbsp;
				<span
					className="f-fm fm-w6-s16"
					style={{
						color: "#20ce8f",
						verticalAlign: "middle",
					}}>
					Add Existing File
				</span>
			</span>
		</>
	);
}
