import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const PrathapChandraShetty = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Prathap Chandra Shetty</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/Prathap_Chandra_Shetty.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Fisheries and Aquaculture Sector Management Expert for FAO of
                the United Nations.
              </li>
              <li>
                Assessor BAP & Global GAP, National Accreditation Board for
                Certification Bodies (NABCB).
              </li>
              <li>
                TWG Member, Global Food Safety Initiative (GFSI). Post-Harvest
                Handling of Terrestrial Animals and Farmed Seafood ( Consultant
                / member).
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              Thirty five years of professional activity in International
              seafood trade, processing practices, Sustainable Aquaculture
              Operations, International Food Standards, Seafood Trading, Trade
              capacity Building, Quality control, Sustainability, Social
              responsibility and Certification.
            </li>
            <li>
              Standard writing with 150+ training manuals in HACCP, Management,
              Projects, and Training programs for the trainers- for all category
              of Fisheries operation and Aquaculture. Multilingual.
            </li>
            <li>
              <b>Techinal Director</b>, The Fish in Company, USA. One among the
              leading seafood supplier in the world, having retail outlets in
              all of the 50 states in the USA.
            </li>
            <li>Executive Director, Emirates Star Fisheries, Dubai, UAE.</li>
            <li>
              Director, Shui Aqua Bios Exports Pvt. Ltd., Karnataka, India.
            </li>
            <li>
              Chief General Manager, Trimarine Food Pvt. Ltd., Cochin, Kerala,
              India.
            </li>
            <li>
              Quality Control Manager – Training and Development, Saudi
              Fisheries Company, Dammam, K.S.A.
            </li>
            <li>Travelled approximately 100 countries.</li>
            <li>
              Developed general regulations and operating guidelines to enable
              Public Sector´s effective marine aquaculture zoning and area
              management, as well as and make recommendations on models of
              services.
            </li>
            <li>
              <b>The International Fisheries Infrastructure Expert</b>, UNIDO
              Project on “Technical and Institutional Capacity Building for
              increase in production and development of the aquaculture and
              fisheries value chains in Ethiopia”.
            </li>
            <li>
              <b>HACCP Advisor </b>, Phase 1 &2, CTG for FAO/UNDP of the United
              Nations HACCP Certification Study and Local Capacity Development
              in Fishery Sector, a joint project , to implement the HACCP, Food
              Safety issues and Fisheries, Procedures for the Safe and Sanitary
              Processing and Importing of Fish and Fishery Products.
            </li>
            <li>
              FAO ( 2019) – HACCP Certification Study and Local Capacity
              Development in Fisheries Industry , BOSASO SOMALIA, January 2019 ,
              total pages 137.
            </li>
          </ul>
          <b>Membership and Affiliation</b>
          <ul className="ulstyle">
            <li>The World Aquaculture Society & Asian-Pacific Chapter.</li>
            <li>Global Aquaculture Alliance .</li>
            <li>
              Best Aquaculture Practices -Aquaculture Certification Council.
            </li>
          </ul>
          <b>Professional Experience</b>
          <ul className="ulstyle">
            <li>
              “ISO 22000 and SA 8000” five days completed courses by The Food
              School,
            </li>
            <li>
              HACCP Auditor, by SURE FISH, 413-3rd Ave., West SEATTLE, WA 98119.
            </li>
            <li>
              Accredited Certifier, training By ACC (Aquaculture Certification
              Council, USA.
            </li>
            <li>
              Project evaluation expert : AAAID (Arab Authority for Agricultural
              Investment and Development) Khartoum, SUDAN, Certifier for
              evaluation of Aquaculture projects in the Middle East.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Masters in Fisheries Science, University of Agriculture Science,
              Bangalore.
            </li>
            <li>
              Bachelors of Fisheries Science with emphasis on Aquaculture.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrathapChandraShetty;
