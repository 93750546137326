import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const KapseBhagwan = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. Kapse Bhagwan</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/kapse_bhagwan.jpg" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Technical Director, Maharashtra Mango Bagayatdar Sangh for
                promotion of quality, production of mango as well as processing
                and expert of Mango from Maharashtra state.
              </li>
            </ul>
            <b>Past</b>
            <br />
            <span>
              Former Director, National Institute of Post-Harvest Technology
              (NIPHT) MSAMB, Pune.
            </span>
            <br />
            <br />
            <ul className="ulstyle">
              <li>
                Promoted Sweet orange and lime on farmers fields under high tech
                cultivation in Maharashtra State after study visit to South
                Africa. Resulted higher yield by 2 to 3 times.
              </li>
              <li>
                The post harvest technology for Mango, Banana for export through
                CA container by sea to EU, USA and standardized for the first
                time with the help of APEDA.
              </li>
              <li>
                Established various export oriented Mango orchards at many
                farmers fields with South Africa & Israel Technology.
              </li>
              <li>Established NIPHT for export of Fruits & Vegetables.</li>
              <li>Developed technology for export of Mango by sea.</li>
              <li>Developed technology for export of Mango by sea.</li>
              <li>Hon’ble Advisor to Paithan Mega Food Park, Aurangabad.</li>
              <li>Member of Mango & Cashew Board Study group – Maharashtra.</li>
              <li>
                Attended and presented research papers in International Mango
                symposium held t in Israel, South Africa.
              </li>
              <li>
                Visited various countries for Marketing of Mangos – Hong Kong,
                Singapore, Malaysia, Thailand, Dubai and Canada.
              </li>
              <li>Standardized the scientific method of Mango Ripening.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Awards</b>
          <ul className="ulstyle">
            <li>International watsave award. 2015 in France.</li>
            <li>Marathwada Ratna Award</li>
            <li>Pinnaclers of Maharashtra.</li>
            <li>“INSID” Award for irrigation Management.</li>
          </ul>
          <b>Education</b>

          <ul className="ulstyle">
            <li>Ph.D. (Horticulture).</li>
            <li>MSC. (Agriculture).</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default KapseBhagwan;
