import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const PVSMGouri = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. P V S M Gouri</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/Dr.-P-V-S-M-Gouri.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Executive Director & CEO , Association of Indian Organic
                Industry, New Delhi
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                A professional with over 25 years of experience spanning
                Corporate, Academic and Regulatory sectors.
              </li>
              <li>
                Technical Advisor, Suminter Organics (India) Pvt Limited) 2018-
                2019).Responsible for strategic advisory assignments for
                agribusiness in the area of certification agriculture export and
                R&D etc.
              </li>
              <li>
                Vice President, Food & Organics, Round Glass H2O) Pvt Ltd
                (2016-2018). Responsible for implementation of Quality
                Management System in the organic food vertical.
              </li>
              <li>
                Advisor, APEDA and National Accreditation Body (NAB) 2003-2015.
                Overall development of the National Organic Program for
                Production (NPOP) implemented by Agricultural & Processed Food
                Export Development Authority (APEDA) under the Ministry of
                Commerce and Industry, Govt of India for development of exports.
                Framing of National Standards under NPOP and Capacity building.
                Establishment and implementation of the Quality Management
                System (QMS) for accreditation of Certification Bodies (CBs),
                assessment & audits of the Certification Bodies.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Acadamic and Membership Expereince</b>
          <ul className="ulstyle">
            <li>
              Assessor with National Body for Accreditation of Certification
              Bodies (NABCB)since September 2018.
            </li>
            <li>Represented APEDA in Codex Alimentarius (2004-2015)</li>
            <li>
              Represented APEDA as an Expert in several National Committees of
              Ministry of Agriculture, Bureau of Indian Standards (BIS), Food
              Standards and Safety Authority (FSSAI), Ministry of Environment
              and Forests (2009-10), Ministry of Textiles, Government of India
              (2009-10) and Planning Commission.
            </li>
            <li>
              The International Task Force on Harmonization and Equivalence in
              Organic Agriculture (IFOAM/UNCTAD/FAO) (2003-2008).
            </li>
            <li>
              Working group member of The Criteria Committee of The
              International Federation for Organic Agricultural Movements
              (IFOAM), Germany (2001-2002).
            </li>
            <li>
              Indira Gandhi National Open University (IGNOU) (2003-2006), New
              Delhi Visiting Faculty on Organic Agriculture Developed the
              curriculum and course material for the certificate course in
              organic farming.
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>Ph.D. in Life Sciences & Post Doctorate in Tissue culture.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PVSMGouri;
