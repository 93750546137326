import React from "react";
// import '../Menu2.css'; // Updated CSS file
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const TargetAudience = () => {
	return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>

			<div className="standards-coverage-container">
				<div className="coverage-inner-container">
					<div className="coverage-section-1">
						<div className="coverage-item">
							<h4 className="coverage-title">Farmer organizations</h4>
						</div>
						<div className="coverage-item">
							<h4 className="coverage-title">
								Institutions in the food supply chain
							</h4>
						</div>
					</div>
					<div className="coverage-section-2">
						<div className="coverage-item">
							<h4 className="coverage-title">Food processors and packers</h4>
						</div>
						<div className="coverage-item">
							<h4 className="coverage-title">Faculty and students</h4>
						</div>
					</div>
					<div className="coverage-section-1">
						<div className="coverage-item">
							<h4 className="coverage-title">Food exporters/importers</h4>
						</div>
						<div className="coverage-item">
							<h4 className="coverage-title">Suppliers of farm inputs</h4>
						</div>
					</div>
					<div className="coverage-section-3">
						<div className="coverage-item">
							<h4 className="coverage-title">Food retailers</h4>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default TargetAudience;
