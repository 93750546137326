import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
// import NotFound from "../components/NotFound";

const generatePage = (pageName, alert) => {
  // console.log(pageName);

  const component = () => require(`../pages/${pageName}`).default;

  try {
    return React.createElement(component());
  } catch (err) {
    // console.log("err", err);
    // return !alert.loading && <NotFound />;
  }
};

const PageRender = () => {
  const { alert } = useSelector((state) => state);
  const { page, id } = useParams();
  // console.log("id", id);
  const { auth } = useSelector((state) => state);

  let pageName = "";
  // if (auth.token) {
  if (page === "") {
    pageName = "dashboard";
    return;
  } else {
    // if (id) {
    // 	pageName = `${page}/${id}`;
    // } else {
    // 	pageName = `${page}`;
    // }
    pageName = `${page}`;
  }
  // }
  // console.log("lokesh", pageName);
  return generatePage(pageName, alert);
};

export default PageRender;
