import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const DevendraPrasad = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Mr. Devendra Prasad</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/devender-prasad.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>Senior Consultant-APEDA, New Delhi.</li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                DGM in Agricultural and Processed Food Products Export
                Development Authority (APEDA), Ministry of Commerce and
                Industry, Government of India involved in export promotion, food
                safety, quality and development of laboratory network for export
                certification of food products from India.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div class="col containerin">
          <b>Significant Achievements</b>
          <ul className="ulstyle">
            <li>
              Development and implementation of export procedures ensuring food
              safety, quality and traceability requirements of importing
              countries.
            </li>
            <li>
              Upgradation of food testing laboratories, food safety management
              system and inspection agencies.
            </li>
            <li>
              Carried out investigation for establishing corrective action for
              export rejections and rapid alerts of EU, USA, GCC countries, CIS,
              Indonesia, Japan and other importing countries.
            </li>
            <li>
              Handled sanitary and phytosanitary and technical barrier to trade
              matters issued by WTO member countries to protect India’s interest
              to ensure smooth trade of food products.
            </li>
            <li>
              Chaired Electronic Working Group on setting up of Maximum Levels
              of Aflatoxins in Ready-to-Eat Peanuts in Codex Committee on
              Contaminant; Okra, Brinjal, Ware Potato, Onion and Shallots
              standards in Codex Committee on Fresh Fruits and Vegetables as
              well as represented India as Regional Coordinator for Asian
              countries in Codex Committee on Fresh Fruits and Vegetables for
              harmonisation of India’s standards with the global standards.
            </li>
          </ul>
          <b>Education</b>

          <ul className="ulstyle">
            <li>Post-Graduation in Public Administration.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default DevendraPrasad;
