import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const SeemaShukla = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div className="container">
        <center>
          <div>
            <h2 className="heading-p1">Dr Seema Shukla</h2>
          </div>
          <div>
            <img className="image" src="../images/Dr-Seema-Shukla.jpg" alt="" />
          </div>
        </center>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Present</b>
          <ul className="ulstyle">
            <li>
              Currently associated with 3 EU projects (ARISE plus, EU Asia
              cooperation on Sanitary and Phyto Sanitary and food regulation,
              BTSF) to be delivered in India, ASEAN and World.
            </li>
            <li>
              Working on Guide on Animal and Animal Products exports to India
              for EU exporters under the project EU Asia Cooperation on
              phytosanitary and food safety regulation (DAHD, FSSAI).
            </li>
            <li>
              Working on Market surveillance technical report preparation for
              Tea, Pulses, Milk on behalf of FSSAI and NCML.
            </li>
            <li>
              Empaneled at WHO as technical expert/ Consultant on Food Safety
              including Quality Assurance to work on South Asia (April
              2021-2024).
            </li>
            <li>
              Certified auditor for HACCP, ISO 9001, ISO 22000 ISO 13485, BRC.
              Assessor for ISO 17020, ISO 17021/ 220003, FSSC-22003 ver5.1 with
              EIC (2012-2018) and NABCB (2018-2022).
            </li>
            <li>
              Food Safety Training Expert for developing sustainable
              institutional framework for training and regulator’s capacity
              building in ASEAN countries.
            </li>
          </ul>
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              22 years of experience in Food Safety at various levels as
              microbiologist, auditor, trainer, consultant, policy maker.
            </li>
            <li>
              Experience of working with regulatory, food safety and other
              governmental authorities in association with EIC FSSAI, EU, USFDA,
              AQSIQ and CFIA, CNCA, ASEAN.
            </li>
            <li>
              Demonstrated competence in SPS measure, codex matters, and science
              based standard setting process.
            </li>
            <li>
              Experience in handling WTO related matters and presented India’s
              approach on SPS, TBT standards. Developed e learning program on
              Introduction to WTO, NTMs and Safer Food for better trade.
            </li>
            <li>
              Completed revision of Guidebook on Plant and Plant products import
              to India covering regulatory paradigm for improved market access.
              Under the project EU Asia Cooperation on phytosanitary and food
              safety regulation.
            </li>
            <li>
              Drafted handbook on good beekeeping practice at EIC involving
              FSSAI and National bee board (2016), review of guide book on good
              aquaculture practice along with MPEDA and CIFT.
            </li>
            <li>
              Development of manual and training materials and conducting
              conferences and workshops and social events of high impact;
              notable one includes USFDA, CFIA and EU programs under CITD
              including aquaculture, good fishing vessel Practices.
            </li>
            <li>
              Coordinator for EU India Capacity Building Initiative for Trade
              Development (CITD) project for the component of food safety and
              SPS (2013-2017).
            </li>
            <li>
              Successfully moderated the delivery of six programs in online mode
              in ASEAN including overseeing the developmental work on food
              safety sampling, risk communication and Risk Based Inspection,
              Food Hygiene and Food Borne disease outbreak. New programs
              Labelling and Health claims, Risk assessment for pesticide
              residues, emergency and recall.
            </li>
            <li>
              Developed two capsule e learning module for Quality Council of
              India (Training and capacity building) department on Introduction
              to WTO and Safer food for better business.
            </li>
            <li>
              Expert in ADB project GMS Cros border Livestock Value chain
              project as ISO 22000 expert to develop policy road map for three
              countries Lao PDR, Cambodia and Myanmar. Worked closely with
              Cambodia MISTI.(2020-2022).
            </li>
            <li>
              Part of various committees and Indian delegation to CASCO, Codex
              committee on CCFH, CCGP and CCNFSD(2014-2021).
            </li>
            <li>
              Conducted more than 200 man-days of compliance audits in sectors
              like meat products, aquaculture, rice, catering.
            </li>
            <li>
              Coordinated several missions for capacity building with Malaysian
              Authority, Nepal, Bhutan, EU, USFDA, CNCA, CFIA and for
              international associations like FAO, ILSI, IFPRI. (20112-2018).
              Worked closely for signing of US India capacity building protocol
              with USFDA and EIC and member of EU India Joint working groups as
              part of EIC (2014-2018).
            </li>
            <li>
              Leading the initiative to develop online portal for import
              rejection monitoring across the departments (EU RASFF). Actively
              participated in review of online portals (e traceability in
              fisheries and laboratory accreditation scheme). (2017-2018).
            </li>
            <li>
              Coordination of the FVO mission to India on peanut and peanut
              products for aflatoxin control; residue control.(2015).
            </li>
          </ul>
          <b>Key Contributions To Government Initiatives</b>
          <ul className="ulstyle">
            <li>
              Facilitating signing of agreement on feed and feed products
              between EIC, MoC&I, India and AQSIQ, China in May 2014.
            </li>
            <li>
              Signing of technical protocol for capacity building between EIC,
              India and USFDA and its operationalization in October 2016.
            </li>
            <li>
              Developing the protocol between EIC, FSSAI and World Bank to
              establish ITCFSAN in 2017.
            </li>
            <li>
              Operationalization of agreement between FSSAI and Netherlands for
              capacity building during 2016-2018
            </li>
            <li>
              Contribution on White paper on Endocrine descriptors for EU
              legislations.
            </li>
            <li>
              Facilitating the capacity building towards inspection of rice,
              Spices, honey, peanuts and seafood.
            </li>
            <li>
              High level dialogue Seminar between India EU on Plant protection
              products complete coordination from inception(September 2016).
            </li>
            <li>
              High level dialogue Seminar between India EU on Veterinary
              medicinal products and AMR complete coordination from inception
              (November 2017).
            </li>
            <li>
              Gap analysis on official control vis-à-vis EU requirements for
              Fishery & aquaculture products (May 2014).
            </li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Indian Institute of Technology, New Delhi : Doctor of Philosophy,
              Supply Chain – Food Safety.
            </li>
            <li>
              G.B Pant University of Agriculture :Master of Technology
              (Agriculture, Food Biotech).
            </li>
            <li>
              Delhi University: Bachelors of Applied Sciences (Food Tech).
            </li>
            <li>HACCP from Royal Institute of Public Health in 2002.</li>
            <li>
              Good Laboratory Practices (GLP) Inspectors as per ISO 17025 OECD
              principles in 2015; auditors’ program in December 2016 by NGCMA,
              Department of Science and Technology.
            </li>
            <li>
              Certified with distinction vide online course entitled “Sanitary
              and Phytosanitary Measures – ET200514E” in 2014.
            </li>
            <li>
              Workshop on SPS and TBT measures 2015 at WTO regional office,
              Bangkok.
            </li>
            <li>
              Workshop on Standards, regulation and health in 2016 by WTO office
              at Geneva.
            </li>
            <li>
              Participated in USDA Food Safety Equivalence Program at four
              locations in US, 2017.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default SeemaShukla;
