import React from "react";
// import '../Menu2.css'; // Updated CSS file
import Footer from "./footer";
import Homecarousel from "./homecarousel";
import Navbar from "./navbar";

const StandardsCoverage = () => {
	return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>

			<div className="standards-coverage-container">
				<div className="coverage-inner-container">
					<div className="coverage-section-1">
						<div className="coverage-item">
							<h4 className="coverage-title">On Farm Production</h4>
						</div>
						<div className="coverage-item">
							<h4 className="coverage-title">Sustainability</h4>
						</div>
					</div>
					<div className="coverage-section-2">
						<div className="coverage-item">
							<h4 className="coverage-title">Post-Harvest</h4>
						</div>
						<div className="coverage-item">
							<h4 className="coverage-title">
								USDA and USFDA across food chain
							</h4>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default StandardsCoverage;
