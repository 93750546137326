import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const VilasATonapi = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. Vilas A Tonapi</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/vilas-a-tonapi.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Member Task Force on enhancing production and productivity of
                Millets-International Year of Millets 2023
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Director .Indian Institute of Millets Research ,a premier
                agricultural research institute engaged in basic and strategic
                research on sorghum and other Millets under Indian Council of
                Agricultural Research (ICAR).
              </li>
              <li>
                Served as Head, Division of Seed Science and Technology, IARI,
                New Delhi.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Major contributions</b>
          <ul className="ulstyle">
            <li>
              Planning, development and management of crop improvement programs
              and strategies food and nutritional security of self and
              cross-pollinated crops for dry and irrigated ecosystems.
            </li>
            <li>
              He has been the consultant to FAO on seed system development.
            </li>
            <li>
              He was part of USDA Global ergot collection and characterization
              mission.
            </li>
            <li>
              Seed production (breeder to certified) of varieties, composites,
              hybrids and their parents.
            </li>
            <li>
              Developmental initiatives in seed Technology, seed quality
              assurance, new seed policy and development of sustainable
              Community seed systems.
            </li>
            <li>
              Farmer participatory seed production, DUS Testing, and seed
              quality control.
            </li>
            <li>
              National and international capacity building initiatives in plant
              breeding and seed production practices.
            </li>
            <li>
              Research on physiological, biochemical and molecular basis of seed
              vigour/longevity.
            </li>
            <li>
              International visit to seven US universities to study crop
              improvement programs, seed systems, biotechnology and IPR
              management.
            </li>
          </ul>
          <b>Awards</b>

          <ul className="ulstyle">
            <li>
              He has been awarded RV Swami Nathan Gold medal for his Ph.D and JN
              TATA endowment award & GRDC fellowship for Post-Doctoral program.
            </li>
            <li>
              ICAR-IISR award for significant contributions in Seed Science &
              Technology and NSP (Crops).
            </li>
            <li>Legume Research Editorial Award.</li>
            <li>ICAR award for Centre of Excellence in Seed Production.</li>
            <li>
              Two awards- One as best DUS centre and one for capacity building
              in PPV&FR.
            </li>
            <li>
              Seeds men Association medal and Award for service to seed
              industry.
            </li>
            <li>
              Best Scientist award of Directorate of Sorghum Research,
              Hyderabad.
            </li>
            <li>
              J.N.TATA Endowment award, GRDC, Australia & USDA scholarships for
              PDF
            </li>
            <li>Kirloskar Vasundhara Mitra Award.</li>
            <li>
              Best AICRP Award for Sorghum-2019 as Project Coordinator Sorghum
              and Small Millets.
            </li>
            <li>Outstanding Institute Award -2018 as Director of ICAR-IIMR.</li>
          </ul>
          <b>Professional Memberships</b>
          <ul className="ulstyle">
            <li>Member, Society for Millet Research, Hyderabad.</li>
            <li>Treasurer, Society for Millets Research.</li>
            <li>President, UAS-AGRICOs Association.</li>
            <li>Member, Indian Society of Seed Technology, New Delhi.</li>
            <li>Member, Indian Society of Plant Physiology.</li>
            <li>Member, Indian society of Agronomy, Hyderabad.</li>
          </ul>

          <b>Education</b>
          <ul className="ulstyle">
            <li>MSc ( Agri).</li>
            <li>Ph.D. in Seed Technology</li>
            <li>
              Post-Doc at University of Queensland, Australia and CRC for
              Tropical Plant Pathology, Brisbane, Australia
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VilasATonapi;
