import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

export default function Addfreecourse(props) {
	// console.log("props", props);
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [showFreeUploadModal, setShowFreeUploadModal] = useState(false);
	const [organisationModal, setOrganisationModal] = useState(false);
	const [courseTitle, setCourseTitle] = useState("");
	const [subject, setSubject] = useState("");
	const [sourceLink, setSourceLink] = useState("");
	const [selectedOrganisation, setSelectedOrganization] = useState("");
	const [organisationArray, setOrganisationArray] = useState([]);
	const [organisationName, setOrganisationName] = useState("");
	const [languages, setLanguages] = useState([]);
	const [selectedLanguage, setSelectedLanguage] = useState("");

	useEffect(() => {
		getDataAPI(`getcourseorganisation`, auth.token).then((res) => {
			console.log("res getcourseorganisations", res.data);
			// setCommunitiesArray(res.data.community);
			setOrganisationArray(res.data.organisations);
		});
	}, []);

	useEffect(() => {
		setLanguages(props.languages);
	}, [props.languages]);

	const handlePushItemsIntoArray = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowFreeUploadModal(false);
		const formData = new FormData();
		var item = {
			languageid: selectedLanguage,
			courseTitle: courseTitle,
			subject: subject,
			sourceLink: sourceLink,
			organisation: selectedOrganisation,
		};
		for (var key in item) {
			formData.append(key, item[key]);
		}
		try {
			const res = await postDataAPI("createfreecourse", formData, auth.token);
			console.log("res", res);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	const handlePushItemIntoFreeOrganisationArray = async () => {
		console.log(organisationName);

		try {
			const res = await postDataAPI(
				"createcourseorganisation",
				{
					name: organisationName,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setOrganisationArray((prev) => [...prev, res.data.organisation]);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}

		setOrganisationModal(false);
	};

	return (
		<>
			<Modal
				show={organisationModal}
				onHide={() => setOrganisationModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Add Organisation
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={() => setOrganisationModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div>
						<div style={{ padding: "15px 0", paddingLeft: "10px" }}>
							<div style={{ fontSize: "13px" }}>Course Organisation Name:</div>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Course Organisation name"
								onChange={(e) => setOrganisationName(e.target.value)}></input>
						</div>
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={organisationName === ""}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handlePushItemIntoFreeOrganisationArray();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal
				show={showFreeUploadModal}
				onHide={() => setShowFreeUploadModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Upload Free Course
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer", textAlign: "right" }}
							onClick={() => setShowFreeUploadModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Organisation:</div>
								<select
									name="category"
									onChange={(e) => setSelectedOrganization(e.target.value)}>
									<option value="">Select</option>
									{organisationArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											setOrganisationModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
						</div>
					</div>

					<div style={{ paddingLeft: "10px", paddingBottom: "10px" }}>
						<div style={{ fontSize: "13px" }}>Language:</div>
						<select
							name="language"
							onChange={(e) => setSelectedLanguage(e.target.value)}>
							<option value="">Select</option>
							{languages.map((e) => {
								return (
									<option value={e.id} key={e.id}>
										{e.name}
									</option>
								);
							})}
						</select>
						<span style={{ marginLeft: "30px" }}>
							<Button
								onClick={() => {
									props.showLanguageModel();
									// setShowLanguageModal(true);
									// setShowUploadModal(false);
								}}
								style={{ background: "#20ce8f", border: "none" }}>
								Add
							</Button>
						</span>
					</div>

					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Course title:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Course Title name"
							onChange={(e) => setCourseTitle(e.target.value)}></input>
					</div>
					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Subject:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Subject"
							onChange={(e) => setSubject(e.target.value)}></input>
					</div>
					<div style={{ paddingTop: "15px", paddingLeft: "10px" }}>
						<div style={{ fontSize: "13px" }}>Source link:</div>
						<input
							type="text"
							className="form-control"
							placeholder="Enter Source link"
							onChange={(e) => setSourceLink(e.target.value)}></input>
					</div>
					<div
						style={{
							textAlign: "center",
							paddingBottom: "10px",
							paddingTop: "20px",
						}}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							onClick={() => {
								handlePushItemsIntoArray();
							}}>
							Upload
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Button
				style={{
					background: "#36BB7D",
					fontSize: "13px",
					borderRadius: "4px",
					border: "none",
					marginRight: "5px",
				}}
				onClick={() => setShowFreeUploadModal(true)}>
				+ Add Free Course
			</Button>
		</>
	);
}
