import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { postDataAPI } from "../utils/fetchData";

export default function FreeCourseRegister(props) {
	const { profile, auth } = useSelector((state) => state);
	const [showModal, setShowModal] = useState(false);
	const [selectedCourseId, setSelectedCourseId] = useState("");
	const initialState = {
		name: "",
		gender: "",
		email: "",
		phone: "",
		city: "",
		country: "",
	};
	const [userData, setUserData] = useState(initialState);
	const [registerEnabled, setRegisterEnabled] = useState(false);

	const dispatch = useDispatch();

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleChangeInput = (e) => {
		const { name, value } = e.target;
		setUserData({ ...userData, [name]: value });
	};

	useEffect(() => {
		setSelectedCourseId(props.courseid);
	}, [props]);

	useEffect(() => {
		if (
			userData.name !== "" &&
			userData.gender !== "" &&
			userData.email !== "" &&
			userData.phone !== "" &&
			userData.city !== "" &&
			userData.country !== ""
		) {
			setRegisterEnabled(true);
		} else {
			setRegisterEnabled(false);
		}
		console.log("userData", userData);
	}, [userData]);

	const handleRegisterCourse = async () => {
		setShowModal(false);
		dispatch({ type: GLOBALTYPES.ALERT, payload: { loading: true } });
		console.log("join clicked", userData);

		try {
			const res = await postDataAPI(
				"createfreeCourseUsers",
				{
					...userData,
					courseid: selectedCourseId,
					userid: auth.user?.id,
				},
				auth.token
			);
			console.log("res", res);
			if (res.status === 200) {
				setShowModal(false);
				window.open(
					(props.link?.includes("https://") ? "" : "http://") + props.link,
					"_blank"
				);
				// window.location.reload();
				// window.location.href = "../mycources";
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal
				show={showModal}
				onHide={handleCloseModal}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row" style={{ background: "#F4F7F9" }}>
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "15px",
								}}>
								Please enter your details to view this course
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "15px", cursor: "pointer" }}
							onClick={handleCloseModal}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<div style={{ padding: "15px 45px 10px" }}>
						<label for="name" style={{ paddingBottom: "5px" }}>
							Full Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="name"
							value={userData.name}
							className="form-control"
							placeholder="Enter Full Name"></input>
						{/* <label
							for="lastname"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Last Name*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="lastname"
							value={userData.lastname}
							className="form-control"
							placeholder="Enter Last Name"></input> */}
						<div
							className="d-flex gap-5  mx-0 mb-1"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							<label for="name">Gender*</label>
							<label htmlFor="male">
								Male&nbsp;
								<input
									type="radio"
									id="male"
									name="gender"
									value="male"
									// defaultChecked
									onChange={handleChangeInput}
								/>
							</label>

							<label htmlFor="female">
								Female&nbsp;
								<input
									type="radio"
									id="female"
									name="gender"
									value="female"
									onChange={handleChangeInput}
								/>
							</label>
						</div>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Email*
						</label>
						<input
							type="email"
							onChange={handleChangeInput}
							name="email"
							value={userData.email}
							className="form-control"
							placeholder="Enter Email"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Mobile Number*
						</label>
						<input
							type="text"
							onChange={handleChangeInput}
							name="phone"
							// style={{ paddingTop: "10px", paddingBottom: "5px" }}
							value={userData.phone}
							className="form-control"
							placeholder="Enter Mobile Number"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							City*
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="city"
							value={userData.city}
							placeholder="Enter City"></input>
						<label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Country*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.country}
							name="country"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="India">India</option>
							<option value="USA">USA</option>
						</select>
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Select Occupation*
						</label>
						<select
							className="form-control select_role select-hidden"
							id="select_role"
							onChange={handleChangeInput}
							value={userData.occupation}
							name="occupation"
							required=""
							profession-selected="0">
							<option value="">Select</option>
							<option value="Farmer">Farmer</option>
							<option value="FPO CEO">FPO CEO</option>
							<option value="Student">Student</option>
							<option value="Quality Professional">Quality Professional</option>
							<option value="Others">Others</option>
						</select> */}
						{/* <label
							for="name"
							style={{ paddingTop: "10px", paddingBottom: "5px" }}>
							Organization
						</label>
						<input
							type="text"
							className="form-control"
							onChange={handleChangeInput}
							name="organization"
							value={userData.organization}
							placeholder="Enter Organization"></input> */}
						{/* <input
							type="checkbox"
							// className="form-control"
							onChange={checkHandler}
							name="isChecked"
							isChecked={userData.isChecked}
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}></input>
						Click on the Box to agree to the platform terms and Privacy Policy */}
						{/* <input
							type="checkbox"
							id="checkbox"
							style={{
								marginTop: "10px",
								marginBottom: "5px",
								marginRight: "5px",
							}}
							checked={userData.isChecked}
							onChange={checkHandler}
						/>
						{/* <label htmlFor="checkbox"> *
						Click on the Box to agree to the platform terms and Privacy Policy{" "} */}
						{/* </label> */}
					</div>
					<div style={{ textAlign: "end", paddingBottom: "10px" }}>
						<Button
							style={{ background: "#20ce8f", border: "none" }}
							disabled={registerEnabled === false}
							onClick={() => {
								// setShowCard(true);
								// setShowUploadModal(false);
								handleRegisterCourse();
							}}>
							Save
						</Button>
					</div>
				</Modal.Body>
			</Modal>
			<Button
				onClick={() => {
					setShowModal(true);
				}}
				style={{ background: "#20ce8f", border: "none" }}>
				Link
			</Button>
		</>
	);
}
