import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const SanthoshJEapen = () => {
  return (
    <>
      {" "}
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr. Santhosh J. Eapen</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/DrSanthoshJEapen.png"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb" style={{ paddingRight: "25px" }}>
            <b>Present</b>
            <ul className="ulstyle">
              <li>President, Indian Society for Spices</li>
              <li>
                Member ,Food Safety Standards Authority of India Scientific
                Panel on Spices & Culinary Herbs
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Former Principal Scientist &Head ICAR Indian Institute of Spices
                Research 2016-2022
              </li>
              <li>
                A research Scientist with over 30 Years of experience in Spices
                and Plantation Crops
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Recognitions</b>
          <ul className="ulstyle">
            <li>Research Publications: 95 (International 39, National 56)</li>
            <li>Patents filed 2</li>
            <li>
              Nematology consultant in Vietnam: Sep – Oct 2009 (FAO, Rome)
            </li>
            <li>
              Chairman, 8th Meeting of the R&D Committee of International Pepper
            </li>
            <li>Community, Kuching, Malaysia: 02-03 May 2019 (IPC, Jakarta)</li>
          </ul>
          <b>Member in National Bodies</b>

          <ul className="ulstyle">
            <li>Fellow, Indian Society for Spices, Kozhikode</li>
            <li>Fellow Indian Society for Plantation Crops ,Kasaragod</li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>Ph.D. in Zoology</li>
            <li>MSc in Zoology</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SanthoshJEapen;
