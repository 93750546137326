import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GLOBALTYPES } from "../redux/actions/globalTypes";
import { getDataAPI, postDataAPI } from "../utils/fetchData";

export default function BookAction(props) {
	const { profile, auth } = useSelector((state) => state);
	const dispatch = useDispatch();
	const [showUploadEbooksModal, setShowUploadEbooksModal] = useState(false);
	const [dlCategorieArray, setDlCategorieArray] = useState([]);
	const [dlSubCategorieArray, setDlSubCategorieArray] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState("");
	const [selectedSubCategory, setSelectedSubCategory] = useState("");
	const [ebBookName, setEbBookName] = useState("");
	const [ebISBN, setEbISBN] = useState("");
	// const [ebSubject, setEbSubject] = useState("");
	const [ebSource, setEbSource] = useState("");
	const [ebAuthor, setEbAuthor] = useState("");
	const [ebPublisher, setEbPublisher] = useState("");
	const [ebCost, setEbCost] = useState("");
	const [eblink, setEbLink] = useState("");
	const [ebFile, setEbFile] = useState("");
	const [selectedLanguage, setSelectedLanguage] = useState("");
	const [languages, setLanguages] = useState([]);

	useEffect(() => {
		// getDataAPI(`getdlcategory`).then((res) => {
		// 	// console.log("dlcategoryarray", res.data.categories);
		// 	// setCommunitiesArray(res.data.community);
		// 	setDlCategorieArray(res.data.categories);
		// });
		// getDataAPI(`getlanguages`, auth.token).then((res) => {
		// 	// console.log("res getlanguages", res.data);
		// 	// setCommunitiesArray(res.data.community);
		// 	setLanguages(res.data.languages);
		// });
		setDlCategorieArray(props.categoriesArray);
		setLanguages(props.languagesArray);
	}, [props]);

	useEffect(() => {
		if (selectedCategory !== "") {
			getDataAPI(`getdlsubcategory/${selectedCategory}`).then((res) => {
				// console.log("res getdl subcategory", res.data);
				// setCommunitiesArray(res.data.community);
				setDlSubCategorieArray(res.data.subcategories);
			});
		}
	}, [selectedCategory]);

	const handleEditBook = async (book) => {
		console.log("book", book);
		setEbBookName(book.name);
		setSelectedCategory(book.categoryid);
		setSelectedSubCategory(book.subcategoryid);
		setSelectedLanguage(book.languageid);
		setEbISBN(book.isbn);
		// setEbSubject(book.subject);
		setEbSource(book.source);
		setEbAuthor(book.author);
		setEbPublisher(book.publisher);
		setEbCost(book.cost);
		setEbLink(book.link);
		setEbFile(book.file);
		setTimeout(() => {
			setShowUploadEbooksModal(true);
		}, 100);
	};

	const handleSetBookInactive = async (book) => {
		console.log("book", book);
		getDataAPI(`inactivebook/${book.id}`, auth.token).then((res, err) => {
			console.log("res inactivebook", res.data);
			if (err) {
				dispatch({
					type: GLOBALTYPES.ALERT,
					payload: { error: err.response.data.msg },
				});
			}
			if (res.status === 200) {
				window.location.reload();
			}
			// setMycommunitiesArray(res.data.community);
		});
	};

	const handleUploadEbook = async () => {
		dispatch({
			type: GLOBALTYPES.ALERT,
			payload: { loading: true },
		});
		setShowUploadEbooksModal(false);

		let item = {
			id: props.book.id,
			ebookname: ebBookName,
			ebauthor: ebAuthor,
			ebcategory: selectedCategory,
			ebsubcategory: selectedSubCategory,
			language: selectedLanguage,
			ebcost: ebCost,
			ebisbn: ebISBN,
			ebpublisher: ebPublisher,
			// ebsubject: ebSubject,
			ebsource: ebSource,
			eblink: eblink,
			ebfile: ebFile,
			communityid: "9ae8b71c-dd3f-4fad-b1ee-e872f951e7af",
		};
		console.log("item", item);
		const formData = new FormData();
		formData.append("files", ebFile);

		for (var key in item) {
			formData.append(
				key,
				typeof item[key] === "object" ? JSON.stringify(item[key]) : item[key]
			);
		}
		try {
			const res = await postDataAPI("updatebook", formData, auth.token);
			console.log("res", res.data);
			if (res.status === 200) {
				setTimeout(() => {
					window.location.reload();
				}, 1000);
			}
		} catch (err) {
			dispatch({
				type: GLOBALTYPES.ALERT,
				payload: { error: err.response.data.msg },
			});
		}
	};

	return (
		<>
			<Modal
				show={showUploadEbooksModal}
				onHide={() => setShowUploadEbooksModal(false)}
				dialogClassName="modal-sm-30px"
				size="md"
				backdropClassName="childmodal"
				// backdrop="static"
				centered
				// onEntered={() => props.handleEntered}
			>
				<Modal.Body style={{ padding: "0px 12px 0px 12px" }}>
					<div className="row">
						<div className="col-11">
							<h2
								style={{
									fontSize: "18px",
									fontWeight: "500",
									padding: "10px",
								}}>
								Edit Ebook
							</h2>
						</div>
						<div
							className="col-1"
							style={{ padding: "10px", cursor: "pointer" }}
							onClick={() => setShowUploadEbooksModal(false)}>
							<img alt="close" src="../images/closeOne.png"></img>
						</div>
					</div>
					<hr style={{ marginTop: "0px" }} />
					<div className="uc-modal-body">
						<div className="uc-detail-btn-wrap uc-share-btn-wrap">
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Categorie:</div>
								<select
									name="category"
									defaultValue={selectedCategory}
									onChange={(e) => setSelectedCategory(e.target.value)}>
									<option value="">Select</option>
									{dlCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.categories();
											// setShowCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Sub Categorie:</div>
								<select
									name="subcategory"
									defaultValue={selectedSubCategory}
									onChange={(e) => setSelectedSubCategory(e.target.value)}>
									<option value="">Select</option>
									{dlSubCategorieArray.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.subcategories();

											// setShowSubCategorieModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div style={{ paddingLeft: "10px" }}>
								<div style={{ fontSize: "13px" }}>Language:</div>
								<select
									name="language"
									value={selectedLanguage}
									onChange={(e) => setSelectedLanguage(e.target.value)}>
									<option value="">Select</option>
									{languages.map((e) => {
										return (
											<option value={e.id} key={e.id}>
												{e.name}
											</option>
										);
									})}
								</select>
								<span style={{ marginLeft: "30px" }}>
									<Button
										onClick={() => {
											props.languages();
											// setShowLanguageModal(true);
											// setShowUploadModal(false);
										}}
										style={{ background: "#20ce8f", border: "none" }}>
										Add
									</Button>
								</span>
							</div>
							<div className="row pb-2 pt-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebBookName}
										placeholder="Book Name"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbBookName(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebCategory}
										placeholder="Category"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCategory(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebISBN}
										placeholder="ISBN"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbISBN(e.target.value)}
									/>
								</div>
							</div>
							{/* <div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSubject}
										placeholder="Subject"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSubject(e.target.value)}
									/>
								</div>
							</div> */}
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebSource}
										placeholder="Source"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbSource(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={ebAuthor}
										placeholder="Author"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbAuthor(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebPublisher}
										id="copytext"
										placeholder="Publisher"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbPublisher(e.target.value)}
									/>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										value={ebCost}
										id="copytext"
										placeholder="Cost Rs."
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbCost(e.target.value)}
									/>
								</div>
								<div style={{ fontSize: "13px", fontWeight: 500 }}>
									(Note: In case of free course, leave the cost field empty!!!)
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="file"
										accept="application/pdf"
										className="form-control"
										id="copytext"
										onChange={(e) => setEbFile(e.target.files[0])}
										style={{ backgroundColor: "#fff" }}
									/>
								</div>
							</div>
							{/* {ebFile} */}
							{typeof ebFile !== "object" && ebFile.split("/")[2]}
							<div style={{ textAlign: "center" }}>OR</div>
							<div className="row pb-2">
								<div className="col-12">
									<input
										type="text"
										className="form-control"
										id="copytext"
										value={eblink}
										placeholder="Link"
										style={{ backgroundColor: "#fff" }}
										onChange={(e) => setEbLink(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div
							className="row"
							style={{
								backgroundColor: "#efefef",
								padding: "10px 10px",
								marginTop: "15px",
							}}>
							<div
								className="col-10"
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "flex-start",
									visibility: "hidden",
								}}>
								<input type="checkbox" id="file" />
								&nbsp;&nbsp;&nbsp; Make announcement post
							</div>

							<div className="col-2">
								<div>
									<Button
										style={{
											background: "#3CCE8F",
											border: "none",
											height: "35px",
											//   width:"40px",
											borderRadius: "15px",
											//   display:"flex",
											//   alignItems:"center",
											//   justifyContent:"end"
										}}
										onClick={() => handleUploadEbook()}>
										Upload
									</Button>
								</div>
							</div>
						</div>
					</div>
				</Modal.Body>
			</Modal>

			<div style={{ marginLeft: "5px" }}>
				<Button
					style={{
						// background:
						// 	"#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-warning mt-2 col-12"
					onClick={() => handleEditBook(props.book)}>
					<i class="fas fa-edit"></i>
					Edit book
				</Button>
				<Button
					style={{
						background: "#E23F1C",
						fontSize: "13px",
						borderRadius: "4px",
						border: "none",
					}}
					className="btn btn-danger mt-2 col-12"
					onClick={() => handleSetBookInactive(props.book)}>
					<i class="fa fa-trash" aria-hidden="true"></i> Delete book
				</Button>
			</div>
		</>
	);
}
