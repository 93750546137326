import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const Aniljauhri = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div className="container">
        <center>
          <div>
            <h2 className="heading-p1">Mr. Anil Jauhri</h2>
          </div>
          <div>
            <h6 className="heading-p2">International Standards Expert</h6>
          </div>
          <div>
            <img className="image" src="../images/aniljauhri.jpg" alt="" />
          </div>
        </center>
      </div>
      <div className="row">
        <div class="col containerin">
          <div>
            <b>
              Ex CEO, National Accreditation Board for Certification Bodies,
              Quality Council of India
            </b>
          </div>
          <br />
          <b>Present</b>
          <ul className="ulstyle">
            <li>
              Post retirement in July, 2019, associated with several
              organizations as an independent expert:{" "}
            </li>
            <li> Member, CDM Accreditation Panel, UNFCCC, Bonn </li>
            <li>
              Member of the Boards for Yoga certification, Naturopathy
              certification and Ayurveda training accreditation set up by the
              Ministry of AYUSH.
            </li>
            <li>
              Chairman, Good Clinical Practice Professional Certification
              Scheme, THSTU, Deptt of Biotechnology
            </li>
            <li>
              Member of the Accreditation Committees of accreditation bodies,
              ASI Gmbh, Bonn and ASI North America
            </li>
            <li>
              Evaluator for ISEAL Alliance for sustainability standards and
              certification schemes.
            </li>
            <li>
              Co-chair of the recently launched initiative of Regulatory
              Representatives and Managers Association related to chemical
              regulation
            </li>
            <li>
              Co-chair of the AMTZ’s steering committee for development of
              conformity assessment schemes in medical device sector such as
              Biomedical Equipment Maintenance Certification Scheme
            </li>
            <li>
              Lead assessor for accreditation bodies like NABCB, ANAB and UAF.
            </li>
            <li>
              Lead peer evaluator for the Asia Pacific Accreditation Cooperation
              (APAC) as well as International Accreditation Forum (IAF).
            </li>
          </ul>
          <span>
            He continues to provide expertise to the government to Ministries
            such as Commerce, AYUSH, Biotechnology and office of the Principal
            Scientific Adviser in India, whenever needed. He continues to be
            member of the Core Group in the Department of Commerce coordinating
            development of technical regulations in India since 2017.
            <br />
            <br /> He writes extensively on standards, regulations and
            conformity assessment related issues.
          </span>
          <br />
          <br />
          <b>Past</b>
          <ul className="ulstyle">
            <li>
              Former CEO of the National Accreditation Board for Certification
              Bodies (NABCB), a constituent Board of the Quality Council of
              India (QCI), and national accreditation body for certification and
              inspection attached to Ministry of Commerce & Industry. During his
              tenure as CEO, NABCB secured a number of international
              equivalences such as Product certification, Inspection, various
              management systems like Food safety, Information security,
              Occupational Health and Safety and Energy management systems and
              Personnel certification.
            </li>
            <li>
              He has been part of India’s trade negotiations with various
              countries, notably with the USA as part of Indian delegation led
              by the Commerce Minister in 2015 and 2017 in Washington DC and
              continues to work with Department of Commerce on trade issues and
              free trade negotiations like with UAE, UK and Australia currently
            </li>
            <li>
              42 plus years of experience in the field of quality, standards,
              certification and accreditation having worked earlier in the
              Bureau of Indian Standards, the national standards body, and the
              Export Inspection Council, India’s official export regulator and
              certification body under the Ministry of Commerce & Industry. Led
              the development of recently launched Good Clinical Practice
              Professional Certification scheme under Clinical Development
              Services Agency of THSTI in DBT as well as accreditation of
              Ayurveda training courses globally under newly created Ayurveda
              Training Accreditation Board in the Rashtriya Ayurveda Vidyapeeth
              of the Ministry of AYUSH.
            </li>
            <li>
              He is nationally and internationally recognized expert having
              worked with such international bodies as UNFCCC, UNIDO, PTB, FAO,
              APO and IFC and was invited as an expert by WTO in 2013 and 2015.
            </li>
            <li>
              Besides being instrumental in supporting regulators like PNGRB,
              FSSAI, CDSCO, BEE etc. in developing systems for using accredited
              3rd party agencies, he also led development of a number of
              voluntary certification schemes of QCI notably AYUSH Mark for
              ayush products, ICMED scheme for medical devices, Yoga
              certification, Traditional healer certification,
              IndGAP/IndiaGHP/India HACCP certifications in agrifood sector,
              Star rating scheme for private security agencies etc.
            </li>
          </ul>
          <div style={{ marginBottom: "10px" }}>
            <b>Education :</b> <span>M Tech, I.I.T Kanpur</span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Aniljauhri;
