import React from 'react';
// import '../Menu2.css'; // New CSS file
import Footer from "./footer";
import Navbar from "./navbar";
import Carousel from "react-multi-carousel";
import Homecarousel from './homecarousel';

const ImpactCreation = () => {
 
  return (
		<>
			<Navbar />
			<div className="" style={{}}>
				<Homecarousel />
			</div>

			<div className="platform-features-container">
				<div className="features-inner-container">
					<div className="features-section-1">
						<div className="feature-item">
							<p className="feature-description">
								Certification of produce under recognized standards enhances
								income for farmers and processors.
							</p>
						</div>
						<div className="feature-item">
							<p className="feature-description">
								Adoption of standards ensures safe food for both the processing
								industry and consumers while boosting exports.
							</p>
						</div>
					</div>
					<div className="features-section-2">
						<div className="feature-item">
							<p className="feature-description">
								Access to educational resources and Model Farms will create a
								pool of digitally empowered entrepreneurs.
							</p>
						</div>
						<div className="feature-item">
							<p className="feature-description">
								Widespread implementation of standards benefits farmers,
								processors, and consumers, elevating India’s position in the
								global market.
							</p>
						</div>
					</div>
					<div className="features-section-3">
						<div className="feature-item">
							<p className="feature-description">
								Creating lasting impact by empowering farmers to reduce their
								carbon footprint through the adoption of global standards
							</p>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default ImpactCreation;
