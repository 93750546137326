import React from "react";
import "../Menu.css";

const JoinUsSection = () => {
  return (
    <>
      <div style={{ padding: "50px" }}>
        <div className="main-joinus-container">
          <div
            style={{
              background: "#15222c",
              backgroundImage: 'url("../images/joinusimagebackground.jpg")',
              backgroundSize: "100%",

              color: "white",
              backgroundRepeat: "repeat-y",
              // backgroundSize: "auto",
              backgroundPosition: "cover",
              position: " relative",

              maxHeight: "max-content",
              width: "100%",
              paddingBottom: "50px",
            }}
          >
            <section className="join-us-section">
              <div className="join-us-content">
                <h2 className="join-us-title">
                  Join Us in Shaping the Future of Sustainable and Profitable
                  Farming
                </h2>
                <p className="join-us-description">
                  An initiative of{" "}
                  <strong>Krishigap Digital Solutions Pvt Ltd</strong>,
                  supported by <strong>Montgomery County, Maryland, USA</strong>
                  , and the <strong>Microsoft Startups Founders Hub</strong>.
                </p>
                <a href="#" className="join-us-button">
                  Join Us
                </a>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinUsSection;
