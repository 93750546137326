import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const GopiKrishnaSanthanam = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div className="container">
        <center>
          <div>
            <h2 className="heading-p1">Dr. Gopi Krishna Santhanam</h2>
          </div>
          <div>
            <img className="image" src="../images/gopikrishna.jpg" alt="" />
          </div>
        </center>
      </div>
      <div className="row">
        <div class="col containerin">
          <span>
            Information Technology and Smart Farming Specialist, Co-Founder and
            CEO, Ascentya, R&D Solutions Pvt Ltd
          </span>
          <br />
          <b>Present</b>
          <ul className="ulstyle">
            <li>Co- Founder and CEO, Ascentya R& D Solutions Pvt. Ltd.</li>
          </ul>
          <b>Past</b>
          <ul className="ulstyle">
            <li>Vice President, Microsoft (2016-2019)</li>
            <li>
              Head of Technology and Business Services, EXPT SolutSolutioSA
            </li>
            <li>Head of Business Solutions, Lloyds N.V., Europe</li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>
              Doctor of Philosophy (PhD) in Management Information Systems
            </li>
            <li>Master of Business Administration ( MBA)</li>
            <li>Bachelor of Science ( BSc) in Computer Science</li>
          </ul>
        </div>
      </div>
      <Footer />{" "}
    </>
  );
};
export default GopiKrishnaSanthanam;
