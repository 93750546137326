import React from "react";
import "../Menu.css"; // Ensure your custom styles are applied here
import Footer from "./footer";
import Navbar from "./navbar";
import Carousel from "react-multi-carousel";
import Homecarousel from "./homecarousel";

const KeyObjectives = () => {
  return (
    <div>
      <Navbar />
      <div className="" style={{}}>
        <Homecarousel />
      </div>
      <div className="page-gap">
        <div className="container">
          <div className="e-con-inner">
            {/* Left Section: Key Objectives */}
            <div className="left-section">
              <h2 className="elementor-heading-title">Key Objectives</h2>
              <div className="objectives-list">
                <div className="objective-item fadeInUp">
                  <p>
                    Empowering stakeholders across the food supply chain by
                    promoting compliance with Global Food Safety and
                    Sustainability Standards through a digital platform.
                  </p>
                </div>
                <div className="objective-item fadeInUp">
                  <p>
                    Bridging the gap between knowledge and practice through
                    model farms, while empowering farmers to reduce their carbon
                    footprint by adhering to these standards.
                  </p>
                </div>
                <div className="objective-item fadeInUp">
                  <p>
                    Expanding the availability of skilled professionals across
                    the food supply chain.
                  </p>
                </div>
                <div className="objective-item fadeInUp">
                  <p>
                    Enhancing the financial sustainability of farmers and
                    processors.
                  </p>
                </div>
                <div className="objective-item fadeInUp">
                  <p>
                    Ensuring the production of safe, high-quality food for
                    consumers worldwide.
                  </p>
                </div>
                <div className="objective-item fadeInUp">
                  <p>
                    Supporting the achievement of the United Nations Sustainable
                    Development Goals (SDGs).
                  </p>
                </div>
              </div>
            </div>

            {/* Right Section: Vision and Mission */}
            <div className="right-section">
              {/* Vision */}
              <div className="vision">
                <div className="icon">
                  <i className="fa fa-eye"></i>
                </div>
                <div className="text">
                  <h3>Vision</h3>
                  <p>
                    To empower all food supply chain stakeholders through a
                    holistic solution for global compliance with food safety and
                    sustainability standards.
                  </p>
                </div>
              </div>

              {/* Mission */}
              <div className="mission">
                <div className="icon">
                  <i aria-hidden="true" className="fas fa-atom"></i>
                </div>
                <div className="text">
                  <h3>Mission</h3>
                  <p>
                    To create a digital platform offering virtual and
                    instructor-led training alongside model farms showcasing
                    best practices and standards compliance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default KeyObjectives;
