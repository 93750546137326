import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const JKumar = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr J. Kumar</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img className="imagesb" src="../images/J-Kumar.png" alt="" />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Served as scientist of wheat pathology at Indian Institute of
                Wheat & Barley Research, Karnal for 24 years.
              </li>
              <li>
                Joint Director, School of Crop Health Biology Research along
                with holding additional charge of Director at ICAR – National
                Institute of Biotic Stress Management (NIBSM), Raipur,
                Chhattisgarh.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Recognitions</b>
          <ul className="ulstyle">
            <li>
              Holder of UNDP & HRDP fellowships, Humboldt fellowship (Germany).
            </li>
            <li>
              Chaudhary Devi Lal Outstanding All India Coordinated Research
              Project award.
            </li>
            <li>Sh. V.P. Gokhale Award of ARI, Pune.</li>
            <li>
              Best research team award of ICAR – IIW&BR, Karnal, Councillorship
              – 2 terms (north zone), ISOMPP, Udaipur.
            </li>
            <li>
              Honored as Member board of management, ICAR – IARI, New Delhi.
            </li>
            <li>
              Member institute management committees of ICAR – Sugarcane
              Breeding Institute, Coimbatore and ICAR – Central Potato Research
              Institute, Shimla.
            </li>
            <li>
              Member, Research Advisory Group, Himalayan Forest Research
              Institute, Shimla, Ph.D. and M.Sc. thesis examiner of Dr. YSPUH&F,
              Nauni, Solan, H.P, IGKV, Raipur, Chhattisgarh.
            </li>
            <li>Member, Academic Council, IGKV, Raipur.</li>
          </ul>
          <b>Education</b>
          <ul className="ulstyle">
            <li>M.Sc.</li>
            <li>Ph.D. in Mycology and Plant Pathology.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default JKumar;
