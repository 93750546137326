// Test.js
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import Question from "./question";

// const questionsData = [
//   {
//     question: "What is the capital of France?",
//     answers: ["Berlin", "Madrid", "Paris", "Rome"],
//   },
//   {
//     question: "What is 2 + 2?",
//     answers: ["3", "4", "5", "6"],
//   },
//   // Add more questions as needed
// ];

const Test = ({ onSubmit, questions, scoretemp, questionsduration }) => {
	// console.log(questions);
	const questionsData = questions;
	const [currentQuestion, setCurrentQuestion] = useState(0);
	const [answers, setAnswers] = useState(
		Array(questionsData.length).fill(null)
	);
	const [timeLeft, setTimeLeft] = useState(
		questionsData.length * 60 * questionsduration
	); // 5 minutes in seconds

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft((prevTime) => prevTime - 1);
		}, 1000);

		if (timeLeft === 0) {
			clearInterval(timer);
			handleSubmit();
		}

		return () => clearInterval(timer);
	}, [timeLeft]);

	// const [score, setScore] = useState(0);

	const handleSelectAnswer = (questionIndex, answerIndex, option) => {
		// console.log("questionIndex", questionIndex);
		// alert(questionsData[questionIndex].question);
		// if (questionsData[questionIndex].answer === option) {
		// 	setScore(score + 1);
		// }
		// alert(questionsData[answerIndex].answer === option);
		const newAnswers = [...answers];
		newAnswers[questionIndex] = option;
		setAnswers(newAnswers);
		// console.log("newAnswers", newAnswers);
	};

	const handleSubmit = () => {
		let score = 0;
		questionsData.forEach((question, index) => {
			if (question.answer === answers[index]) {
				score++;
			}
		});
		onSubmit(answers);
		scoretemp(score);
	};

	return (
		<div style={{ margin: "20px" }}>
			<div style={{ textAlign: "right" }}>
				Time left: {Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? "0" : ""}
				{timeLeft % 60}
			</div>
			<Question
				question={questionsData[currentQuestion]}
				index={currentQuestion}
				onSelectAnswer={handleSelectAnswer}
				selectedAnswer={answers[currentQuestion]}
			/>
			<div
				style={{
					textAlign: "center",
					marginTop: "20px",
					display: "flex",
					justifyContent: "space-between",
				}}>
				{/* {currentQuestion > 0 && ( */}
				<Button
					onClick={() => setCurrentQuestion(currentQuestion - 1)}
					disabled={currentQuestion === 0}>
					Previous
				</Button>
				{/* )} */}
				{currentQuestion < questionsData.length - 1 ? (
					<Button onClick={() => setCurrentQuestion(currentQuestion + 1)}>
						Next
					</Button>
				) : (
					<Button onClick={handleSubmit}>Submit</Button>
				)}
			</div>
		</div>
	);
};

export default Test;
