import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const RamaReddyKovvuri = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />
      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Rama Reddy Kovvuri</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/rama_reddy_kovvuri.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
              <b>Present</b>
              <br />
              <span>
                Presently working in capacity of a Vice President for Tvisha
                Technologies Incorporation, Hyderabad.
              </span>
              <br />
            </div>
            <div style={{ paddingBottom: "15px", lineHeight: "30px" }}>
              <b>Past</b>
              <br />
              <span>
                CEO, Vihaan Digital Marketing, Hyderabad, Telangana.
                <br /> Mr. Rama Reddy is currently serving as CEO of Vihaan
                Digital Marketing. He is featured in the CEO Story and Economic
                Times for his thought leadership and industry expertise. He is a
                visionary and has broad experience in business strategy, digital
                marketing, technology, operations, client engagement, partner
                development, and people management to name a few.
                <br />
                <br /> Earlier he was serving as Vice President at eFresh India,
                leading IT and Agritech Solutions from envisioning “Digital
                Platform for Agribusiness (DPA)” to setting up Command Hubs to
                monitor Farmer Development Centers (FDC) and Marketplace
                integration. Mr. Rama Reddy technical expertise in choosing and
                building robust IT infrastructure has helped the company lower
                its capital and operating cost and increase its savings.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Significant Achievements</b>
          <ul className="ulstyle">
            <li>
              Mr. Rama Reddy is a Certified Lean Six Sigma Green Belt holder and
              Certified Lead Auditor ISO QMS 9001:2008, trained & certified on
              Official Course for ‘Capability Maturity Model Integration’
              (SEI-CMMI) and also holds ITIL V3 Foundation Certificate in IT
              Service Management.
            </li>
            <li>
              Prior to joining Tvisha Technologies Incorporation, Mr.Rama Reddy
              has held a variety of IT management positions including Project
              Management, Head of Information Security and Quality Assurance for
              BSE listed IT services company AJEL Limited and brings 20 years of
              vast experience.
            </li>
            <li>
              His expertise is in Digital Transformation, PMO, IT modernization,
              Projects Automation, Client Expertise, Business Consulting,
              Program Management and KPI Management.
            </li>
            <li>
              Leadership and Process Excellence: Act as a thought leader in
              defining success criteria and understand business needs of
              customers in an ever-changing business environment. Contribute to
              and leads strategic plans and documents for Organization.
            </li>
            <li>
              Instrumental in implementing technology initiatives at eFresh
              Agribusiness Solutions.
            </li>
          </ul>

          <b>Education</b>
          <ul className="ulstyle">
            <li>B.Sc Computer Sciences</li>
            <li>Microsoft Certified Professional.</li>
            <li>Certified Lead Auditor ISO QMS</li>
            <li>ITIL V3 in IT Service Management</li>
            <li>Lean Sigma Green Belt</li>
            <li>Trained and Certified on SEI-CMMI</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default RamaReddyKovvuri;
