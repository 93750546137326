import React from 'react';
import '../Menu.css';
import JoinUsSection from './joinus';
import Carousel from "react-multi-carousel";
import Navbar from './navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ModelFarm2 = () => {
   

    return (
        <> 
         
            <div className="model-farm2-container">
             
                <div className="model-farm2-key-components-container">
                    <div className="model-farm2-key-components">
                        <h3>B. On-Farm Production Excellence Through Global Standards</h3>
                    </div>
                </div>

                <div className="model-farm2-key-point">
                    <p>Empowering farmers and agribusinesses with knowledge of global food safety and sustainability standards to elevate the food safety and sustainability ecosystem for a secure future.</p>
                </div>
                <div className="model-farm2-inner">
                    <div className='model-farm2-main-heading'>Why Choose Our eLearning Platform?</div>

                </div>

                <div className='row widget-image-matter-container'>
                    <div className='col single-widget'>
                        <div className='model-farm2-images-container'>
                            <img
                                src='..\images\model-farmimage-2-1.jpg'
                                alt='modelfarm image'
                                className='model-farm2-image'
                            />

                        </div>
                        <div className='widget-text-title'>
                            <h4> Your Digital Consultant for Global Standards</h4>
                        </div>
                        <div className='widget-text-matter'>
                            <p>We understand the challenges farmers and agribusinesses face in today’s global market. Our platform equips you with the skills and knowledge needed to meet global standards through a unique combination of self-paced and instructor-led training.</p>
                        </div>






                    </div>
                    <div className='col single-widget'>
                        <div className='model-farm2-images-container'>
                            <img
                                src='..\images\model-farmimage-2-2.jpg'
                                alt='modelfarm image'
                                className='model-farm2-image'
                            />

                        </div>
                        <div className='widget-text-title'>
                            <h4>Self-Paced Learning: Flexibility at Your Fingertips</h4>
                        </div>

                        <div className='widget-text-matter'>
                            <p>We understand the challenges farmers and agribusinesses face in today’s global market. Our platform equips you with the skills and knowledge needed to meet global standards through a unique combination of self-paced and instructor-led training.</p>
                        </div>

                    </div>
                    <div className='col single-widget'>
                        <div className='model-farm2-images-container'>
                            <img
                                src='..\images\model-farmimage-2-3.jpg'
                                alt='modelfarm image'
                                className='model-farm2-image'
                            />

                        </div>
                        <div className='widget-text-title'>
                            <h4> Instructor-Led Training: Expert Guidance for Deeper Insights</h4>
                        </div>
                        <div className='widget-text-matter'>
                            <p>We understand the challenges farmers and agribusinesses face in today’s global market. Our platform equips you with the skills and knowledge needed to meet global standards through a unique combination of self-paced and instructor-led training.</p>
                        </div>


                    </div>

                </div>
                <div className='row widget-image-matter-container2'>
                    <div className='col single-widget'>
                        <div className='model-farmdown2-images-container'>
                            <img
                                src='..\images\model-farmimage-2-4.jpg'
                                alt='modelfarm image'
                                className='model-farm2-image'
                            />

                        </div>
                    </div>
                    <div className='col single-widget'>
                        <div>
                            <h4 className="dark-green-heading center caps-heading">How This Platform Benefits You</h4>
                        
                        
                        <ul>
                            <li >
                                <div className='model2-list-item'>
                                    <div className="elementor-icon-list-icon">
                                        <svg aria-hidden="true" class="e-font-icon-svg e-fas-leaf" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path></svg>						</div>
                                    <span class=" elementor2-icon-list-text"><strong>Farmers: </strong>
                                        <span style={{ fontWeight: '500', paddingLeft:'5px' }}> Achieve compliance with global standards, access premium markets, increase income, and run sustainable farming operations.</span></span>
                                </div>
                            </li>
                            <li>
                                <div className='model2-list-item'>
                                    <div className="elementor-icon-list-icon">
                                        <svg aria-hidden="true" class="e-font-icon-svg e-fas-leaf" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M546.2 9.7c-5.6-12.5-21.6-13-28.3-1.2C486.9 62.4 431.4 96 368 96h-80C182 96 96 182 96 288c0 7 .8 13.7 1.5 20.5C161.3 262.8 253.4 224 384 224c8.8 0 16 7.2 16 16s-7.2 16-16 16C132.6 256 26 410.1 2.4 468c-6.6 16.3 1.2 34.9 17.5 41.6 16.4 6.8 35-1.1 41.8-17.3 1.5-3.6 20.9-47.9 71.9-90.6 32.4 43.9 94 85.8 174.9 77.2C465.5 467.5 576 326.7 576 154.3c0-50.2-10.8-102.2-29.8-144.6z"></path></svg>						</div>
                                    <span className="elementor2-icon-list-text"><strong>Corporate Houses: </strong>
                                        <span style={{ fontWeight: '500' ,paddingLeft:'5px'  }}> Ensure that your supply chains meet international food safety standards, enhancing brand reputation and product quality.</span></span>
                                </div>
                            </li>
                        </ul>
                        </div>

                    
                    </div>
                    <div className='col single-widget'>
                        <div className='model-farmdown2-images-container'>
                            <img
                                src='..\images\model-farmimage-2-5.jpg'
                                alt='modelfarm image'
                                className='model-farm2-image'
                            />

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ModelFarm2;
