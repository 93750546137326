import React from "react";
import "../Menu.css";
import Navbar from "./navbar";
import Footer from "./footer";
import Homecarousel from "./homecarousel";

const Amarnathsharma = () => {
  return (
    <>
      <Navbar />
      <Homecarousel />

      <div class="row">
        <div className="col">
          <h2 className="headingp1sb">Dr Amar Nath Sharma</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-3">
          <div>
            <img
              className="imagesb"
              src="../images/amar-nath-sharma.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="col-9">
          <div className="containerin2sb">
            <b>Present</b>
            <ul className="ulstyle">
              <li>
                Founder member and General Secretary of Society for Soybean
                Research and Development at ICAR-Indian Institute of Soybean
                Research, Indore, India.
              </li>
            </ul>
            <b>Past</b>
            <ul className="ulstyle">
              <li>
                Worked as a Consultant with Solidaridad Regional Expertise
                Center (SREC) under Solidaridad Asia Network, New Delhi (From
                July 2020 to March 2023) to provide technical support to
                strengthen ongoing Smart Agri Project (SAP) in Soybean which is
                based on LOT, sensors and development of advisory based on the
                parameters and information received through SAP platform etc.
              </li>
              <li>Head, Crop Protection and I/C AICRP on Soybean.</li>
              <li>Principal Investigator of AICRP on Soybean (Entomology).</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div class="col containerin">
          <b>Recognitions</b>
          <ul className="ulstyle">
            <li>
              Life Member of Indian Society of Oilseeds Research, Directorate of
              Oilseed Research, Hyderabad, India.
            </li>
            <li>
              Member of a high-level team constituted by the ICAR, Govt. of
              India, for surveying the soybean areas in the States of Rajasthan
              (2000), Madhya Pradesh (2003) and Maharashtra (2008) affected by
              Spodoptera lituradamage.
            </li>
            <li>
              Expert-member in the State level Steering Committee of
              “Awareness-cum-surveillance program for major pests of soybean in
              soybean-cotton based cropping system in Maharashtra.
            </li>
            <li>
              Member of Technical Committee to formulate Indian Standards for
              Sustainable Soybean (ISSS).
            </li>
          </ul>
          <b>Education</b>

          <ul className="ulstyle">
            <li>M.Sc. (Zoology),Barkatulla University, Bhopal, MP.</li>
            <li>
              Ph.D. (Agricultural Entomology), GB Pant University, Pantnagar.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Amarnathsharma;
