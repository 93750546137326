
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Sample data for looping
const items = [
	{
		id: 1,
		name: "John Doe",
		time: "Posted 2 hours ago",
		content:
			"Here's some engaging text content in the container. It provides a brief overview of something important.",
	},
	{
		id: 2,
		name: "Jane Smith",
		time: "Posted 1 day ago",
		content:
			"This is another important piece of content that can be looped inside the carousel.",
	},
	{
		id: 3,
		name: "Mark Johnson",
		time: "Posted 3 days ago",
		content:
			"Highlighting a third item in this loop for demonstration purposes.",
	},
	{
		id: 4,
		name: "Emily Clark",
		time: "Posted 5 days ago",
		content:
			"Some valuable content goes here, looping inside the carousel structure.",
	},
	{
		id: 5,
		name: "David Lee",
		time: "Posted 1 week ago",
		content:
			"This final item shows how looping works inside the carousel for multiple items.",
	},
];

// Carousel settings
const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 1024 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 1024, min: 768 },
		items: 2,
	},
	tablet: {
		breakpoint: { max: 768, min: 464 },
		items: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

 const Carousel1 =()=> {
	return (
    <div className="container">
    <Carousel
      responsive={responsive}
      arrows={true}
      autoPlay
      infinite
      slidesToSlide={1}
    >
      {items.map((item) => (
        <div
          className="rating section4-text"
          key={item.id}
          style={{
            border: "1px solid #1F664480",
            padding: "20px",
            margin: "20px",
            borderRadius: "10px",
            // width: "400px",
            height: "300px",
            // boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {/* Main Text Content */}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              paddingTop: "10px",
              paddingBottom: "20px",
            }}
          >
            {/* Profile Picture */}

            <div
              style={{
                fontSize: "20px",
                color: "#BBE4A3",
                display: "flex",
                columnGap: "10px",
              }}
            >
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
              <i class="fa fa-star"></i>
            </div>
          </div>
          <div style={{ marginBottom: "20px" }}>
            <p
              className="section4-text"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                // color: "#333",
                lineHeight: "1.5",
              }}
            >
              {item.content}
            </p>
          </div>

          {/* Profile Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderTop: "1px solid #ddd",
              paddingTop: "10px",
            }}
          >
            {/* Profile Picture */}
            <img
              src="../images/common-user.svg" // Replace with the actual profile picture source
              alt="Profile Pic"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />

            {/* Profile Name and Posted Time */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span
                className="section4-text"
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  color: "",
                }}
              >
                {item.name}
              </span>
              <span
                className="section4-text"
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "",
                }}
              >
                {item.time}
              </span>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
    </div>
  );
}

export default Carousel1;
